import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-homepage',
 
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss'
})
export class HomepageComponent {
    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any; VehicleBlocked: any;
    classdata: any; customerdata: any;
    classname: any;

    @ViewChild('dragImage', { static: true }) dragImageElement!: ElementRef<HTMLImageElement>;
    @ViewChild('zoomImage', { static: true }) zoomImageElement!: ElementRef<HTMLImageElement>;
    @ViewChild('magnifier', { static: true }) magnifierElement!: ElementRef<HTMLDivElement>;

    scale = 2; // Zoom scale
    isLoggedIn: any;
    subdata: any;
    approvedStatus: number | undefined;
    activeTab: number = 0;
    dataresult: any;
    //dataresults: any;
    dataresult1: any;
    dataresult2: any;
    dataresults: any[] = [];
    filteredDataResults: any[] = [];
    districtList: string[] = [];
    selectedDistrict: string = '';
    filteredDataResults1: any[];
    filteredDataResults2: any[];
    filteredDataResults3: any[];
    districtLists: unknown[];
    districtList1: unknown[];
    districtList2: unknown[];

    dataresul: any;
    paginatedData: any[] = []; // This will hold the data to be displayed on the current page
    currentPage: number = 1; // The current page number
    itemsPerPage: number = 10; // Number of items per page

    filteredData: any;
    selectedState: any;
    statelist2: unknown[];
    statelist1: unknown[];
    statelist: unknown[];

    Searchingusers: any;
    seracingdata: any;
    Subscriptions1: any;
    Subscriptions: any;
    SubscriptionStatus: string;
   // filteredDataResults4: any;
    dataresult7: any;
    dataresult8: any;
    filteredDataResults5 = [];
    RegName: any;
    dataResults4 = []; // Assume this is your original data source.
    filteredDataResults4 = []; // This will hold the filtered data.

    uniqueFinancerNames: unknown[] = [];
    uniqueVehicleNumber: unknown[] = [];
    uniqueName: unknown[] = [];
    uniqueAddress: unknown[] = [];
    selectedFinancerName: string = '';
    selectedVehicle: string = '';
    selectedName: string = '';
    selectedAddress: string = '';
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.LoginDetails = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails);

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        })

    }

    ngOnInit() {
        this.getAbsconded();
        this.getSezier();
        this.getSezierYard();
        this.getRTA();
        this.getcustomer();
        this.getblacklist();
      //  this.subscription();
        this.GetSubscriptions();
        
        //   this.getRegistration();       

    }


    getcustomer() {
        debugger
        var uploadfile = new FormData()
        uploadfile.append("FinancierID", this.LoginDetails[0].RegID);
        uploadfile.append("Flag", '5');
        var url = "api/FinancierLaw/Get_Financier_Users";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.customerdata = data;
            this.filteredData = this.customerdata;
            this.updatePaginatedData();
        })
    }


    updatePaginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.paginatedData = this.customerdata.slice(startIndex, endIndex);
    }

    nextPage() {
        if ((this.currentPage * this.itemsPerPage) < this.customerdata.length) {
            this.currentPage++;
            this.updatePaginatedData();
        }
    }

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.updatePaginatedData();
        }
    }

    toggleStatus(item: any): void {
        debugger
        item.VehicleBlocked = !item.VehicleBlocked; // Toggle the value
            var arr = [{
                VehicleBlocked: item.VehicleBlocked,
                VehicleRegNo: item.VehicleRegNo,
            }]
            var uploadfile = new FormData();
            uploadfile.append("Param", JSON.stringify(arr));
            uploadfile.append("Flag", '6');
            var url = "api/FinancierLaw/FA_NoticeFormsCRUD";
            this.generalService.Postdata1(url, uploadfile).subscribe(data => {
                if (data == 'SUCCESS') {
                    alert("Your Form Updated successfully");
                }
            })       

    }

    setActiveTab(index: number) {
        this.activeTab = index;
    }
    onConciliationClick() {
        if (!this.generalService.isRegistered()) {
            this.router.navigate(['/Registration']);
        } else if (!this.generalService.isLoggedIn()) {
            this.router.navigate(['/login']).then(() => {
                this.router.navigate(['/home']);
            });
        } else {
            this.router.navigate(['/home']);
        }
    }

    GetSubscriptions() {
        debugger;
        var obj = [{}];
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(obj));
        UploadFile.append("Flag", '4');

        var url = "api/FinancierLaw/FA_subscriptionCRUD";

        this.generalService.PostData2(url, UploadFile).then((data: any) => {
            debugger;
            this.Subscriptions1 = data;

            // Filter subscriptions with ApprovedStatus = 1
            const approvedSubscriptions = this.Subscriptions1.filter((s: any) => s.ApprovedStatus == 1);

            // Store the approvedStatus from the subscription
            if (approvedSubscriptions.length > 0) {
                this.approvedStatus = approvedSubscriptions[0].ApprovedStatus;
            } else {
                this.approvedStatus = 0; // Default value when no approved subscription is found
            }

            if (this.LoginDetails != null && this.LoginDetails.length > 0) {
                const exists = approvedSubscriptions.some((s: any) => s.RegID === this.LoginDetails[0].RegID);

                // Subscription status check
                if (exists) {
                    this.SubscriptionStatus = "Subscribed";
                } else {
                    this.SubscriptionStatus = "Not Subscribed";
                }
            } else {
                this.SubscriptionStatus = "Not Logged In";
            }
        }, err => {
            this.generalService.presentToast("Something went wrong. Please try again later");
        });
    }

    onCardClick(): void {
        debugger;

        if (this.LoginDetails !== undefined && this.LoginDetails.length > 0) {
            // Navigate to different pages based on 'approvedStatus'
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform']);  // Navigate to the notice form page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page if not logged in
        }
    }


    subscription() {
        debugger;

        this.arr = [];
       // const arr = [{}];

        const uploadfile = new FormData();
        uploadfile.append('Param1', this.LoginDetails[0].RegID);
       // uploadfile.append('Flag', '4');

        const url = 'api/FinancierLaw/Get_subcriptionlist';

        console.log('Sending data to API:', uploadfile);

        this.generalService.PostData2(url, uploadfile).then(
            (data: any) => {
                debugger;
                this.subdata = data;

                const loginRegID = this.LoginDetails[0].RegID;

                this.approvedStatus = this.subdata[0].ApprovalStatusID;

                //if (filteredData.length > 0) {
                //    this.approvedStatus = filteredData[0].ApprovedStatus;
                //    console.log('Approved Status:', this.approvedStatus);
                //} else {
                //    console.log('No matching RegID found.');
                //    this.approvedStatus = undefined; // Ensure `approvedStatus` is set to undefined if no match
                //}
            },
            error => {
               // console.error('API call failed:', error);
               // alert("Failed to sign in: An error occurred while processing your request.");
            }
        );
    }

     onCardClickold(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
    onCardClick1(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform1']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
     onCardClick2(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform2']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
    onCardClick3(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform3']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
     onCardClick4(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform4']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
    onCardClick5(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform5']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
    onCardClick6(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform6']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
    onCardClick7(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform7']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
    onCardClick8(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/form35']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
    onCardClick9(): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/form36']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }


   

    getSezier() {
        debugger
        var uploadfile = new FormData()
        uploadfile.append("FinancierID", this.LoginDetails[0].RegID);
        uploadfile.append("Flag", '1');
        var url = "api/FinancierLaw/Get_Financier_Users";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult = data;
            this.filteredDataResults1 = this.dataresult;
            this.districtLists = [...new Set(this.dataresult.map(item => item.DistrictName))];
            this.statelist = [...new Set(this.dataresult.map(item => item.StateName))];
        })
    }

    getSezierYard() {
        debugger
        var uploadfile = new FormData()
        uploadfile.append("FinancierID", this.LoginDetails[0].RegID);
        uploadfile.append("Flag", '2');
        var url = "api/FinancierLaw/Get_Financier_Users";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult1 = data;
            this.filteredDataResults2 = this.dataresult1;
            this.districtList1 = [...new Set(this.dataresult1.map(item => item.DistrictName))];
            this.statelist1 = [...new Set(this.dataresult1.map(item => item.StateName))];

        })
    }

    getRTA() {
        debugger
        var uploadfile = new FormData()
        uploadfile.append("FinancierID", this.LoginDetails[0].RegID);
        uploadfile.append("Flag", '3');
        var url = "api/FinancierLaw/Get_Financier_Users";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult2 = data;
            this.filteredDataResults3 = this.dataresult2;
            this.districtList2 = [...new Set(this.dataresult2.map(item => item.DistrictName))];
            this.statelist2 = [...new Set(this.dataresult2.map(item => item.StateName))];

        })
    }
    getAbsconded() {
        debugger
        this.arr = [];

      var arr = [{

       }];
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(arr));
        uploadfile.append("Flag", '4');
        var url = "api/FinancierLaw/AbscondedVehiclesCRUD";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult7 = data;
            this.filteredDataResults4 = this.dataresult7;
           // this.districtList2 = [...new Set(this.dataresult2.map(item => item.DistrictName))];
          //  this.statelist2 = [...new Set(this.dataresult2.map(item => item.StateName))];

             this.uniqueFinancerNames = [...new Set((this.dataresult7 || []).map(item => item.FinancerName || ''))];
            this.uniqueVehicleNumber = [...new Set((this.dataresult7 || []).map(item => item.VehicleNumber || ''))];

        })
    }
    deleteItem(avid: string) {
        debugger
        this.arr = [];

        var arr = [{
            AVID:avid
        }];
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(arr));
        uploadfile.append("Flag", '3');
        var url = "api/FinancierLaw/AbscondedVehiclesCRUD";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult7 = data;
            this.filteredDataResults4 = this.dataresult7;
            if (data == 'SUCCESS') {

                alert("Data successfully deleted");
               // localStorage.setItem('AgreementNo', value.AgreementNo);
                //  this.router.navigate(['/FinacialformPDF']);
              //  this.router.navigate(['/finalnotice']);
                this.getAbsconded();

            }
            // this.districtList2 = [...new Set(this.dataresult2.map(item => item.DistrictName))];
            //  this.statelist2 = [...new Set(this.dataresult2.map(item => item.StateName))];

            this.uniqueFinancerNames = [...new Set((this.dataresult7 || []).map(item => item.FinancerName || ''))];

        })
    }
    deleteItem1(BLid: string) {
        debugger
        this.arr = [];

        var arr = [{
            BLid: BLid
        }];
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(arr));
        uploadfile.append("Flag", '3');
        var url = "api/FinancierLaw/BlacklistCRUD";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult7 = data;
            this.filteredDataResults4 = this.dataresult7;
            if (data == 'SUCCESS') {

                alert("Data successfully deleted");
                // localStorage.setItem('AgreementNo', value.AgreementNo);
                //  this.router.navigate(['/FinacialformPDF']);
                //  this.router.navigate(['/finalnotice']);
                this.getblacklist();

            }
            // this.districtList2 = [...new Set(this.dataresult2.map(item => item.DistrictName))];
            //  this.statelist2 = [...new Set(this.dataresult2.map(item => item.StateName))];

            this.uniqueFinancerNames = [...new Set((this.dataresult7 || []).map(item => item.FinancerName || ''))];

        })
    }
    filterData7() {
        if (this.selectedFinancerName) {
            this.filteredDataResults4 = this.dataresult7.filter(item => item.FinancerName === this.selectedFinancerName);
        } else {
            this.filteredDataResults4 = [...this.dataresult7];
        }
    }
    filterData8() {
        if (this.selectedVehicle) {
            this.filteredDataResults4 = this.dataresult7.filter(item => item.VehicleNumber === this.selectedVehicle);
        } else {
            this.filteredDataResults4 = [...this.dataresult7];
        }
    }
    filterData9() {
        // If both dropdowns are empty, show all data
        if ((!this.selectedName || this.selectedName === '') && (!this.selectedAddress || this.selectedAddress === '')) {
            this.filteredDataResults5 = [...this.dataresult8];
        } else {
            // Filter by Name and Address (if they are selected)
            this.filteredDataResults5 = this.dataresult8.filter(item => {
                const nameFilter = !this.selectedName || item.Name === this.selectedName;
                const addressFilter = !this.selectedAddress || item.Address === this.selectedAddress;
                return nameFilter && addressFilter;
            });
        }
    }

    getblacklist() {
        debugger;
        this.arr = [];

        var arr = [{}];
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(arr));
        uploadfile.append("Flag", '4');

        var url = "api/FinancierLaw/BlacklistCRUD";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger;
            this.dataresult8 = data;

            // Initially, show all data where CreatedBy matches RegID
            this.filteredDataResults5 = this.dataresult8.filter(item => item.CreatedBy === this.LoginDetails[0].RegID);

            this.RegName = this.LoginDetails[0].Name;

            // Process lists for dropdowns
            this.uniqueName = [...new Set((this.dataresult8 || []).map(item => item.Name || ''))];
            this.uniqueAddress = [...new Set((this.dataresult8 || []).map(item => item.Address || ''))];
        });
    }



    filterByDistrict() {
        debugger
        if (this.selectedDistrict) {
            this.filteredDataResults = this.dataresults.filter(item => item.DistrictName === this.selectedDistrict);
        } else {
            this.filteredDataResults = [...this.dataresults];
        }
    }
    filterByDistricts() {
        debugger
        if (this.selectedDistrict) {
            this.filteredDataResults1 = this.dataresult.filter(item => item.DistrictName === this.selectedDistrict);
        } else {
            this.filteredDataResults1 = [...this.dataresult];
        }
    }
    filterByDistricts1() {

        if (this.selectedDistrict) {
            this.filteredDataResults2 = this.dataresult1.filter(item => item.DistrictName === this.selectedDistrict);
        } else {
            this.filteredDataResults2 = [...this.dataresult1];
        }
    }
    filterByDistricts2() {

        if (this.selectedDistrict) {
            this.filteredDataResults3 = this.dataresult2.filter(item => item.DistrictName === this.selectedDistrict);
        } else {
            this.filteredDataResults3 = [...this.dataresult2];
        }
    }

    filterbystate() {
        debugger
        if (this.selectedState) {
            this.filteredDataResults1 = this.dataresult.filter(item => item.StateName === this.selectedState);
        } else {
            this.filteredDataResults1 = [...this.dataresult];
        }
    }

    filterbystate1() {
        if (this.selectedState) {
            this.filteredDataResults2 = this.dataresult1.filter(item => item.StateName === this.selectedState);
        } else {
            this.filteredDataResults2 = [...this.dataresult1];
        }
    }

    filterbystate2() {
        if (this.selectedState) {
            this.filteredDataResults3 = this.dataresult2.filter(item => item.StateName === this.selectedState);
        } else {
            this.filteredDataResults3 = [...this.dataresult2];
        }
    }

    searchdata() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.seracingdata);
        uploadfile.append("REGID", this.LoginDetails[0].RegID)
        var url = "api/FinancierLaw/Search_Customers_based_CID";
        this.generalService.Postdata1(url, uploadfile).subscribe(data => {
            debugger
            this.paginatedData = data;
        })
    }

    formatStatus(isBlocked: boolean): string {
        debugger
        return isBlocked ? 'Active' : 'Blocked'; // Format status
    }    

    filterData() {
        this.filteredDataResults1 = this.dataresult.filter(item => {
            const matchesSearch = item.Name.toLowerCase().includes(this.seracingdata.toLowerCase()) ||
                item.Mobile.includes(this.seracingdata) ||
                item.EMail.toLowerCase().includes(this.seracingdata.toLowerCase());
            const matchesState = !this.selectedState || item.StateName === this.selectedState;
            const matchesDistrict = !this.selectedDistrict || item.DistrictName === this.selectedDistrict;

            return matchesSearch && matchesState && matchesDistrict;
        });
    }

    clearSearch() {
        this.seracingdata = '';
        this.selectedState = '';
        this.selectedDistrict = '';
        this.filterData(); // Reset filter
    }

    filterdata1() {
        const searchLower = this.seracingdata.toLowerCase();
        this.filteredData = this.customerdata.filter(item =>
            item.VehicleRegNo.toLowerCase().includes(searchLower)
        );
        this.currentPage = 1; // Reset to first page after filtering
        this.updatePaginatedData(); // Update paginated data based on filtered results
    }

    cleaserserc1() {
        this.seracingdata = '';
        this.filterdata1();
    }

    serachroute() {
        debugger
        this.router.navigate(['/searchrefrence']);
    }


}
