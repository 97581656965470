<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Vehicle Auctioned Notice
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Vehicle Auctioned Notice</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>


                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Borrower Name*</label>
                                    <input type="text" formControlName="BorrowerName" placeholder="Enter Your Borrower Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress">Barrower Address*</label>
                                    <input type="text" formControlName="BarrowerAddress" placeholder="Enter Your Barrower Address" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Barrower Mobile*</label>
                                    <input type="text" formControlName="BarrowerMobile" placeholder="Enter Your Barrower Mobile" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="SuretyName">Surety Name*</label>
                                    <input type="text" formControlName="SuretyName" placeholder="Enter Your Surety Name" id="VehicleRegNo" required>
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="SuretyAddress">Surety Address*</label>
                                    <input type="text" formControlName="SuretyAddress" placeholder="Enter Your Surety Address" id="BorrowerName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="SuretyMobile">Surety Mobile*</label>
                                    <input type="text" formControlName="SuretyMobile" placeholder="Enter Your Surety Mobile" id="BorrowerName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="VehicleRegNo">Vehicle Reg No</label>
                                    <input type="text" formControlName="VehicleRegNo" placeholder="Enter Your Vehicle RegNo" id="BarrowerAddress" required (keyup)="convertToUppercase()">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="DisputeResolutionDate">Dispute Resolution Date</label>
                                    <input type="date" formControlName="DisputeResolutionDate" placeholder="Enter Your Dispute Resolution Date" id="LoanAmount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="DueAmount">Due Amount</label>
                                    <input type="text" formControlName="DueAmount" placeholder="Enter Your Due Amount" id="DueAmount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="VehicleActionedDate">Vehicle Actioned Date</label>
                                    <input type="date" formControlName="VehicleActionedDate" placeholder="Enter Your Vehicle Actioned Date" id="AuctionedDate" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="AuctionReceivedMoney">Auction Received Money</label>
                                    <input type="text" formControlName="AuctionReceivedMoney" placeholder="Enter Your Auction Received Money" id="AuctionedDate" required>
                                </div>
                            </div> <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="OutstandingAmount">Outstanding Amount</label>
                                    <input type="text" formControlName="OutstandingAmount" placeholder="Enter Your Outstanding Amount" id="AuctionedDate" required>
                                </div>
                            </div>



                            <div class="form-group">
                                <!-- If AgreementNo is null, undefined, or empty, show Submit button -->
                                <button *ngIf="aggrementNo==undefined" class="btn" (click)="getRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>

                                <!-- If AgreementNo has a value, show Update button -->
                                <button *ngIf="aggrementNo !==undefined" class="btn" (click)="updateRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>
                            </div>



                            <!--<div class="form-group" (click)="getRegistration(RegisterForm.value)">

                                <button class="btn">
                                    <b>Submit</b>
                                </button>

                            </div>-->
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
    </div>
