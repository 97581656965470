import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-homepage',
 
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss'
})
export class HomepageComponent {
    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any; VehicleBlocked: any;
    classdata: any; customerdata: any;
    classname: any;

    @ViewChild('dragImage', { static: true }) dragImageElement!: ElementRef<HTMLImageElement>;
    @ViewChild('zoomImage', { static: true }) zoomImageElement!: ElementRef<HTMLImageElement>;
    @ViewChild('magnifier', { static: true }) magnifierElement!: ElementRef<HTMLDivElement>;

    scale = 2; // Zoom scale
    isLoggedIn: any;
    subdata: any;
    approvedStatus: number | undefined;
    activeTab: number = 0;
    dataresult: any;
    //dataresults: any;
    dataresult1: any;
    dataresult2: any;
    dataresults: any[] = [];
    filteredDataResults: any[] = [];
    districtList: string[] = [];
    selectedDistrict: string = '';
    filteredDataResults1: any[];
    filteredDataResults2: any[];
    filteredDataResults3: any[];
    districtLists: unknown[];
    districtList1: unknown[];
    districtList2: unknown[];

    dataresul: any;  

    filteredData: any;
    selectedState: any;
    statelist2: unknown[];
    statelist1: unknown[];
    statelist: unknown[];

    Searchingusers: any;
    seracingdata: any;
    Subscriptions1: any;
    Subscriptions: any;
    SubscriptionStatus: string;
   // filteredDataResults4: any;
    dataresult7: any;
    dataresult8: any;
    filteredDataResults5 = [];
    RegName: any;
    dataResults4 = []; // Assume this is your original data source.
    filteredDataResults4 = []; // This will hold the filtered data.

    uniqueFinancerNames: unknown[] = [];
    uniqueVehicleNumber: unknown[] = [];
    uniqueName: unknown[] = [];
    uniqueAddress: unknown[] = [];
    selectedFinancerName: string = '';
    selectedVehicle: string = '';
    selectedName: string = '';
    selectedAddress: string = '';
    districtList3: unknown[];
    statelist3: unknown[]; secondsalebuyerdata: any;
    secondsalesellerdata: any; searchText: string = '';
    sortdirection = { daysDifference : true}
    buyersortdirec = {daysremaing : true}

    filtering: any[] = []; // Filtered data to display
    nameFilter: string = '';  // Two-way bound input for Name filter
    totalPages: any;
    Adscandinglist: string = '';
    RTAList: string = '';
    SeizarYard: string = '';
    SizerList: string = '';         
    RTALISTData: any[] = [];
    AbscandData: any[] = [];
    SizerYardData: any[] = [];
    SizerData: any[] = [];
    BlacklistData: any[] = [];
    paginatedData: any[] = []; // This will hold the data to be displayed on the current page
    currentPage: number = 1; // The current page number
    itemsPerPage: number = 10; // Number of items per page
    totalPagesRTA: any;
    totalPagesBlack: any;
    SizerstotalPages: any;
    SizerYardtotalPages: any;
    AbscandTotalpages: any;
    RTAcurrentPage: number = 1; // The current page number
    SeconBuyerData:any[] = [];
    SecondBuyyerupdate: any[] = [];
    SecondSellerData: any[] = []; SecondBuyertotalPages: any;
    SecondBuyerList: string = '';
    SecondSellerList: string = '';
    SecondSellertotalPages: any;

    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.LoginDetails = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails);
       // this.GetSubscriptions();
       // this.subscription();
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        })
       
    }

    ngOnInit() {
        this.getRTA();
        this.getsecondsalebuyer();
        this.getsecondsaleseller();
        this.getAbsconded();
        this.getSezier();
        this.getSezierYard();
     
        this.getcustomer();
        this.getblacklist();
      //  this.subscription();
      //  this.GetSubscriptions();
        
        //   this.getRegistration();       
        if (this.LoginDetails && (this.LoginDetails[0]?.RoleID === 4 || this.LoginDetails[0]?.RoleID === 5)) {
            this.activeTab = 5; // Automatically set the active tab to 5 for RoleID 4 or 5
        }
    }
    GetSubscriptions1() {
        debugger;
        // Initialize FormData object
        const uploadFile = new FormData();


        if (this.LoginDetails && this.LoginDetails.length > 0) {
            // Append parameters to FormData
            uploadFile.append("Param1", this.LoginDetails[0].RegID);

            const url = "api/FinancierLaw/Get_subcriptionlist";

            // Call the API to get the subscription list
            this.generalService.PostData2(url, uploadFile).then((data: any) => {
                debugger;
                this.Subscriptions1 = data;

                // Check if subscriptions are available and set approvedStatus
                if (this.Subscriptions1 && this.Subscriptions1.length > 0) {
                    this.approvedStatus = this.Subscriptions1[0].ApprovedStatus;
                } else {
                    this.approvedStatus = undefined; // Reset to empty if no subscriptions found
                    alert("No subscriptions found.");
                }
            }).catch(err => {
                this.generalService.presentToast("Something went wrong. Please try again later");
            });
        } else {
            // User is not logged in
            this.SubscriptionStatus = "Not Logged In";
            //this.approvedStatus = ""; // Reset to empty if not logged in
        }
    }

    getsecondsalebuyer() {
        var uploadfile = new FormData();
        uploadfile.append("Param1", this.LoginDetails[0].RegID);
        uploadfile.append("Param2", '1');
        var url = "api/FinancierLaw/Get_Seller_Buyer_Details_basedOn_SecondSales";
        this.generalService.PostData2(url, uploadfile).then(data => {
            this.secondsalebuyerdata = data;
            this.SeconBuyerData = this.secondsalebuyerdata;

            this.SecondBuyertotalPages = Math.ceil(this.SeconBuyerData.length / this.itemsPerPage);
            this.secondBuyerpaginate();
        })
    }

    fiterSecondBuyer() {
        debugger
        const SecondBuyerFilter = this.SecondBuyerList.toLowerCase().trim();
        if (!SecondBuyerFilter) {
            this.SeconBuyerData = this.secondsalebuyerdata;
        } else {
            this.SeconBuyerData = this.secondsalebuyerdata.filter(item => {
                return item.SellerName.toLowerCase().includes(SecondBuyerFilter)
                    || item.SecondsellerFilter.toLowerCase().includes(SecondBuyerFilter)
                    || item.FinancierName.toLowerCase().includes(SecondBuyerFilter)
            })
        }
        this.SecondBuyertotalPages = Math.ceil(this.SeconBuyerData.length / this.itemsPerPage);
        this.secondBuyerpaginate();
    }

    secondBuyerpaginate() {
        const StartIndex = (this.currentPage - 1) * this.itemsPerPage;
        const EndIndex = StartIndex + this.itemsPerPage;
        this.SecondBuyyerupdate = this.SeconBuyerData.slice(StartIndex, EndIndex)
    }

    secondBuyerprevious() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.secondBuyerpaginate();
        }
    }

    secondBuyernext() {
        debugger
        if (this.currentPage < this.SecondBuyertotalPages) {
            this.currentPage++;
            this.secondBuyerpaginate();
        }
    }

    sortdaysremaingbuyer() {
        this.buyersortdirec.daysremaing = !this.buyersortdirec.daysremaing
        this.secondsalebuyerdata.sort((a, b) => {
            return this.buyersortdirec.daysremaing ? a.DaysDifference - b.DaysDifference : b.DaysDifference - a.DaysDifference
        });
    }

    getsecondsaleseller() {
        var uploadfile = new FormData();
        uploadfile.append("Param1", this.LoginDetails[0].RegID);
        uploadfile.append("Param2", '2');
        var url = "api/FinancierLaw/Get_Seller_Buyer_Details_basedOn_SecondSales";
        this.generalService.PostData2(url, uploadfile).then(data => {
            this.secondsalesellerdata = data;
            this.SecondSellerData = this.secondsalesellerdata;

            this.SecondSellertotalPages = Math.ceil(this.SecondSellerData.length / this.itemsPerPage);
            this.secondsellerpaginate();
        })
    }

    fiterSecondseller() {
        debugger
        const SecondsellerFilter = this.SecondSellerList.toLowerCase().trim();
        if (!SecondsellerFilter) {
            this.SecondSellerData = this.secondsalebuyerdata;
        } else {
            this.SecondSellerData = this.secondsalesellerdata.filter(item => {
                return item.BuyerName.toLowerCase().includes(SecondsellerFilter) || item.VehicleRegistrationNo.toLowerCase().includes(SecondsellerFilter)
            })
        }
        this.SecondSellertotalPages = Math.ceil(this.SecondSellerData.length / this.itemsPerPage);
        this.secondsellerpaginate();
    }

    secondsellerpaginate() {
        const StartIndex = (this.currentPage - 1) * this.itemsPerPage;
        const EndIndex = StartIndex + this.itemsPerPage;
        this.SecondBuyyerupdate = this.SecondSellerData.slice(StartIndex, EndIndex)
    }

    secondsellerprevious() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.secondsellerpaginate();
        }
    }

    secondsellernext() {
        debugger
        if (this.currentPage < this.SecondSellertotalPages) {
            this.currentPage++;
            this.secondsellerpaginate();
        }
    }

    sortdaysremaning() {
        this.sortdirection.daysDifference = !this.sortdirection.daysDifference
        this.secondsalesellerdata.sort((a, b) => {
            return this.sortdirection.daysDifference ? a.DaysDifference - b.DaysDifference : b.DaysDifference - a.DaysDifference
        });

    }
  


    getcustomer() {
        debugger
        var uploadfile = new FormData()
        uploadfile.append("FinancierID", this.LoginDetails[0].RegID);
        uploadfile.append("Flag", '5');
        var url = "api/FinancierLaw/Get_Financier_Users";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.customerdata = data;
            this.filteredData = this.customerdata;
            this.totalPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
            this.updatePaginatedData();
        })
    }


    updatePaginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.paginatedData = this.customerdata.slice(startIndex, endIndex);
    }

    nextPage() {
        if ((this.currentPage * this.itemsPerPage) < this.customerdata.length) {
            this.currentPage++;
            this.updatePaginatedData();
        }
    }

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.updatePaginatedData();
        }
    }

    toggleStatus(item: any): void {
        debugger
        item.VehicleBlocked = !item.VehicleBlocked; // Toggle the value
            var arr = [{
                VehicleBlocked: item.VehicleBlocked,
                VehicleRegNo: item.VehicleRegNo,
            }]
            var uploadfile = new FormData();
            uploadfile.append("Param", JSON.stringify(arr));
            uploadfile.append("Flag", '6');
            var url = "api/FinancierLaw/FA_NoticeFormsCRUD";
            this.generalService.Postdata1(url, uploadfile).subscribe(data => {
                if (data == 'SUCCESS') {
                    alert("Your Form Updated successfully");
                }
            })       

    }

    setActiveTab(index: number) {
        this.activeTab = index;
        this.getsecondsalebuyer();
    }
    onConciliationClick() {
        if (!this.generalService.isRegistered()) {
            this.router.navigate(['/Registration']);
        } else if (!this.generalService.isLoggedIn()) {
            this.router.navigate(['/login']).then(() => {
                this.router.navigate(['/home']);
            });
        } else {
            this.router.navigate(['/home']);
        }
    }



    GetSubscriptions(): Promise<void> {
        debugger;
        const uploadFile = new FormData();

        if (!this.LoginDetails || this.LoginDetails.length === 0) {
            this.SubscriptionStatus = "Not Logged In";
            return Promise.resolve();
        }

        uploadFile.append("Param1", this.LoginDetails[0].RegID);
        const url = "api/FinancierLaw/Get_subcriptionlist";

        return this.generalService.PostData2(url, uploadFile).then((data: any) => {
            debugger;
            this.Subscriptions1 = data;

            if (this.Subscriptions1 && this.Subscriptions1.length > 0) {
                this.approvedStatus = this.Subscriptions1[0].ApprovedStatus;
            } else {
                this.approvedStatus = undefined; // Reset if no subscriptions found
                alert("No subscriptions found.");
            }
        }).catch(err => {
            this.generalService.presentToast("Something went wrong. Please try again later");
        });
    }

    async onCardClick(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/noticeform' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }




 

     onCardClickold(): void {
         debugger;

        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform']);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }
    //onCardClick1(): void {
    //    debugger;

    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/noticeform1']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick1(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/noticeform1' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
    // onCardClick2(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/noticeform2']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick2(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/noticeform2' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
    //onCardClick3(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/noticeform3']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick3(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/noticeform3' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
    // onCardClick4(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/noticeform4']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick4(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/noticeform4' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
    //onCardClick5(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/noticeform5']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick5(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            if (this.LoginDetails[0].RoleID === 9) {
                this.router.navigate([this.approvedStatus === 1 ? '/Agreementform' : '/Subcription']);
            } else {
                this.router.navigate([this.approvedStatus === 1 ? '/noticeform5' : '/Subcription']);
            }
        } else {
            this.router.navigate(['/Login']);
        }
    }
    //onCardClick6(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/noticeform6']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick6(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/noticeform6' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
    //onCardClick7(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/noticeform7']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick7(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            if (this.LoginDetails[0].RoleID === 9) {
                this.router.navigate([this.approvedStatus === 1 ? '/Surrenderform' : '/Subcription']);
            } else {
                this.router.navigate([this.approvedStatus === 1 ? '/noticeform7' : '/Subcription']);
            }
        } else {
            this.router.navigate(['/Login']);
        }
    }

   // Agreementform
    //onCardClick8(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/form35']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick8(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/form35' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
    //onCardClick9(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/form36']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick9(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/form36' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
 //onCardClick11(): void {
 //       debugger;
 //       if (this.LoginDetails != undefined) {
 //           if (this.approvedStatus === 1) {
 //               this.router.navigate(['/Sellform']);  // Navigate to the navbar page if approvedStatus is 1
 //           } else {
 //               this.router.navigate(['/Subcription']);  // Navigate to the subscription page
 //           }
 //       } else {
 //           this.router.navigate(['/Login']);  // Navigate to the login page
 //       }
 //   }
    async onCardClick11(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/Sellform' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
    //onCardClick12(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/Buyform']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}
    async onCardClick12(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/Buyform' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }
    //  onCardClick13(): void {
    //    debugger;
    //    if (this.LoginDetails != undefined) {
    //        if (this.approvedStatus === 1) {
    //            this.router.navigate(['/Legalform']);  // Navigate to the navbar page if approvedStatus is 1
    //        } else {
    //            this.router.navigate(['/Subcription']);  // Navigate to the subscription page
    //        }
    //    } else {
    //        this.router.navigate(['/Login']);  // Navigate to the login page
    //    }
    //}

    async onCardClick13(): Promise<void> {
        debugger;
        await this.GetSubscriptions();

        if (this.LoginDetails && this.LoginDetails.length > 0) {
            this.router.navigate([this.approvedStatus === 1 ? '/Legalform' : '/Subcription']);
        } else {
            this.router.navigate(['/Login']);
        }
    }

    getSezier() {
        debugger
        var uploadfile = new FormData()
        uploadfile.append("FinancierID", '');
        uploadfile.append("Flag", '1');
        var url = "api/FinancierLaw/Get_Financier_Users";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult = data;
            this.filteredDataResults1 = this.dataresult;

            this.SizerstotalPages = Math.ceil(this.filteredDataResults1.length / this.itemsPerPage);
            this.sizerpaginate();
        })
    }

    fitersizer() {
        const SizersFilter = this.SizerList.toLowerCase().trim();
        if (!SizersFilter) {
            this.filteredDataResults1 = this.dataresult;
        } else {
            this.filteredDataResults1 = this.dataresult.filter(item => {
                return item.Name.toLowerCase().includes(SizersFilter) || item.Mobile.toLowerCase().includes(SizersFilter)
                    || item.DistrictName.toLowerCase().includes(SizersFilter) || item.StateName.toLowerCase().includes(SizersFilter)
            })
        }
        this.totalPages = Math.ceil(this.filteredDataResults1.length / this.itemsPerPage);
        this.sizerpaginate();
    }

    sizerpaginate() {
        const StartIndex = (this.currentPage - 1) * this.itemsPerPage;
        const EndIndex = StartIndex + this.itemsPerPage;
        this.SizerData = this.filteredDataResults1.slice(StartIndex, EndIndex)
    }

    sizerprevious() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.sizerpaginate();
        }
    }

    sizernext() {
        debugger
        if (this.currentPage < this.SizerstotalPages) {
            this.currentPage++;
            this.sizerpaginate();
        }
    }


    getSezierYard() {
        debugger
        var uploadfile = new FormData()
        uploadfile.append("FinancierID", '');
        uploadfile.append("Flag", '2');
        var url = "api/FinancierLaw/Get_Financier_Users";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult1 = data;
            this.filteredDataResults2 = this.dataresult1;

            this.SizerYardtotalPages = Math.ceil(this.filteredDataResults2.length / this.itemsPerPage);
            this.sizeryardpaginate();

        })
    }

    sizerYardFilter() {
        const sizeryardfilter = this.SeizarYard.toLowerCase().trim();
        if (!sizeryardfilter) {
            this.filteredDataResults2 = this.dataresult1;
        } else {
            this.filteredDataResults2 = this.dataresult1.filter(item => {
                return item.Name.toLowerCase().includes(sizeryardfilter) || item.Mobile.toLowerCase().includes(sizeryardfilter)
                    || item.CompanyAddress.toLowerCase().includes(sizeryardfilter) || item.DistrictName.toLowerCase().includes(sizeryardfilter)
                    || item.StateName.toLowerCase().includes(sizeryardfilter)
            });
        }

        this.totalPages = Math.ceil(this.filteredDataResults2.length / this.itemsPerPage);
        this.sizeryardpaginate();
    }

    sizeryardpaginate() {
        const StatIndex = (this.currentPage - 1) * this.itemsPerPage;
        const EndIndex = StatIndex + this.itemsPerPage;
        this.SizerYardData = this.filteredDataResults2.slice(StatIndex, EndIndex)
    }

    sizerYardprevious() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.sizeryardpaginate();
        }
    }

    sizerYardnext() {
        debugger
        if (this.currentPage < this.SizerYardtotalPages) {
            this.currentPage++;
            this.sizeryardpaginate();
        }
    }

    getRTA() {
        debugger
        var uploadfile = new FormData()
        uploadfile.append("FinancierID", '');
        uploadfile.append("Flag", '3');
        var url = "api/FinancierLaw/Get_Financier_Users";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult2 = data;
            this.filteredDataResults3 = this.dataresult2;
            //pagination

            this.itemsPerPage = 10;  // Set your desired items per page here
            this.currentPage = 1;  // Reset current page to 1

            console.log("Total Records:", this.filteredDataResults3.length); // Debugging log
            this.totalPagesRTA = Math.ceil(this.filteredDataResults3.length / this.itemsPerPage);
            this.updatePaginatData();  // Call method to set initial paginated data

        })
    }

    RtaFilter() {
        debugger
        const RtaFiltervalue = this.RTAList.toLowerCase().trim();
        if (!RtaFiltervalue) {
            this.filteredDataResults3 = this.dataresult2;
        } else {
            this.filteredDataResults3 = this.dataresult2.filter(item => {
                return item.Name.toLowerCase().includes(RtaFiltervalue) || item.Mobile.toLowerCase().includes(RtaFiltervalue)
                    || item.DistrictName.toLowerCase().includes(RtaFiltervalue) || item.StateName.toLowerCase().includes(RtaFiltervalue)
            });
        }

        this.totalPagesRTA = Math.ceil(this.filteredDataResults3.length / this.itemsPerPage);
        this.updatePaginatData();
    }

    updatePaginatData() {
        debugger
        const StatIndex = (this.currentPage - 1) * this.itemsPerPage;
        const EndIndex = StatIndex + this.itemsPerPage;
        this.RTALISTData = this.filteredDataResults3.slice(StatIndex, EndIndex)
    }

    // Navigate to the previous page
    PreviousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.updatePaginatData();
        }
    }

    // Navigate to the next page
    NextPage() {
        debugger
     //   this.totalPagesRTA = localStorage.getItem("totalPages");
        if (this.currentPage < this.totalPagesRTA) {
            this.currentPage++;
            this.updatePaginatData();
            console.log("Next Page Clicked: ", this.currentPage);
            console.log("Data for Current Page: ", this.RTALISTData);
        } else {
       //  console.log("Already on the last page! Total Pages:", totalPages);  // Debugging log
        }
    }


    getAbsconded() {
        debugger
        this.arr = [];

      var arr = [{

       }];
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(arr));
        uploadfile.append("Flag", '4');
        var url = "api/FinancierLaw/AbscondedVehiclesCRUD";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult7 = data;
            this.filteredDataResults4 = this.dataresult7;

            this.currentPage === 1;

            this.AbscandTotalpages = Math.ceil(this.filteredDataResults4.length / this.itemsPerPage)
            this.ABSUpdatePaginate();

        })
    }
    abscandFilter() {
        debugger
        const ascandfiltervalue = this.Adscandinglist.toLowerCase().trim();
        if (!ascandfiltervalue) {
            this.filteredDataResults4 = this.dataresult7;
        } else {
            this.filteredDataResults4 = this.dataresult7.filter(item => {
                return item.BorrowerName.toLowerCase().includes(ascandfiltervalue) || item.BorrowerMobileNumber.toLowerCase().includes(ascandfiltervalue)
                    || item.VehicleNumber.toLowerCase().includes(ascandfiltervalue) || item.DistrictName.toLowerCase().includes(ascandfiltervalue)
                    || item.StateName.toLowerCase().includes(ascandfiltervalue)
            })
        }
        this.currentPage === 1;
        this.totalPages = Math.ceil(this.filteredDataResults4.length / this.itemsPerPage);
        this.ABSUpdatePaginate();

    }

    ABSUpdatePaginate() {
        this.currentPage === 1
        const StatIndex = (this.currentPage - 1) * this.itemsPerPage;
        const EndIndex = StatIndex + this.itemsPerPage
        this.AbscandData = this.filteredDataResults4.slice(StatIndex, EndIndex)
    }


    // Navigate to the previous page
    PreviousABSPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.ABSUpdatePaginate();
        }
    }

    // Navigate to the next page
    NextABSPage() {
        if (this.currentPage < this.AbscandTotalpages) {
            this.currentPage++;
            this.ABSUpdatePaginate  ();
        }
    }

    deleteItem(avid: string) {
        debugger
        this.arr = [];

        var arr = [{
            AVID:avid
        }];
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(arr));
        uploadfile.append("Flag", '3');
        var url = "api/FinancierLaw/AbscondedVehiclesCRUD";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult7 = data;
            this.filteredDataResults4 = this.dataresult7;
            if (data == 'SUCCESS') {

                alert("Data successfully deleted");
              
                this.getAbsconded();

            }
           

            this.uniqueFinancerNames = [...new Set((this.dataresult7 || []).map(item => item.FinancerName || ''))];

        })
    }
    deleteItem1(BLid: string) {
        debugger
        this.arr = [];

        var arr = [{
            BLid: BLid
        }];
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(arr));
        uploadfile.append("Flag", '3');
        var url = "api/FinancierLaw/BlacklistCRUD";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger
            this.dataresult7 = data;
            this.filteredDataResults4 = this.dataresult7;
            if (data == 'SUCCESS') {

                alert("Data successfully deleted");
           
                this.getblacklist();

            }
           
            this.uniqueFinancerNames = [...new Set((this.dataresult7 || []).map(item => item.FinancerName || ''))];

        })
    }
   
   

    getblacklist() {
        debugger;
        this.arr = [];

        var arr = [{}];
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(arr));
        uploadfile.append("Flag", '4');

        var url = "api/FinancierLaw/BlacklistCRUD";
        this.generalService.PostData(url, uploadfile).subscribe(data => {
            debugger;
            this.dataresult8 = data;
            this.filtering = this.dataresult8;

            this.totalPagesBlack = Math.ceil(this.filtering.length / this.itemsPerPage);
            this.BlockListpagination();
        });
    }

    applyFilter() {
        debugger
        const filterValue = this.nameFilter.toLowerCase().trim(); // Trim for better matching
        // If search field is empty, show all records
        if (!filterValue) {
            this.filtering = this.dataresult8;  // Reset to show all data
        } else {
            // Filter the original dataresult8 array by matching the Name
            this.filtering = this.dataresult8.filter(item => {
                return item.Name.toLowerCase().includes(filterValue) || item.Address.toLowerCase().includes(filterValue)
                    || item.FinancerName.toLowerCase().includes(filterValue)
            });
        }
        this.totalPagesBlack = Math.ceil(this.filtering.length / this.itemsPerPage);
        this.BlockListpagination();
    }

    BlockListpagination() {
        const StratIndex = (this.currentPage - 1) * this.itemsPerPage;
        const EndIndex = StratIndex + this.itemsPerPage
        this.BlacklistData = this.filtering.slice(StratIndex, EndIndex)
    }

    previouesBlack() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.BlockListpagination();
        }
    }

    nextBlack() {
        debugger
        if (this.currentPage < this.totalPagesBlack) {
            this.currentPage++;
            this.BlockListpagination();
        }
    }

  

    searchdata() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.seracingdata);
        uploadfile.append("REGID", this.LoginDetails[0].RegID)
        var url = "api/FinancierLaw/Search_Customers_based_CID";
        this.generalService.Postdata1(url, uploadfile).subscribe(data => {
            debugger
            this.paginatedData = data;
        })
    }

    formatStatus(isBlocked: boolean): string {
        debugger
        return isBlocked ? 'Active' : 'Blocked'; // Format status
    }    

    filterData() {
        this.filteredDataResults1 = this.dataresult.filter(item => {
            const matchesSearch = item.Name.toLowerCase().includes(this.seracingdata.toLowerCase()) ||
                item.Mobile.includes(this.seracingdata) ||
                item.EMail.toLowerCase().includes(this.seracingdata.toLowerCase());
            const matchesState = !this.selectedState || item.StateName === this.selectedState;
            const matchesDistrict = !this.selectedDistrict || item.DistrictName === this.selectedDistrict;

            return matchesSearch && matchesState && matchesDistrict;
        });
    }

    clearSearch() {
        this.seracingdata = '';
        this.selectedState = '';
        this.nameFilter = '';
        this.Adscandinglist = '';
        this.RTAList = '';
        this.SeizarYard = '';
        this.selectedDistrict = '';
        this.SecondBuyerList = '';
        this.SecondSellerList = '';
        this.filterData(); // Reset filter
        this.updatePaginatedData();
        this.applyFilter();
        this.abscandFilter();
        this.RtaFilter();
        this.sizerYardFilter();
        this.fiterSecondBuyer();
        this.fiterSecondseller();
    }

    filterdata1() {
        const searchLower = this.seracingdata.toLowerCase();
        this.filteredData = this.customerdata.filter(item =>
            item.VehicleRegNo.toLowerCase().includes(searchLower)
        );
        this.currentPage = 1; // Reset to first page after filtering
        this.updatePaginatedData(); // Update paginated data based on filtered results
    }

    cleaserserc1() {
        this.seracingdata = '';
        this.filterdata1();
    }

    serachroute() {
        debugger
        this.router.navigate(['/searchrefrence']);
    }


}
