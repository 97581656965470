

<div class="pt-100 bgblue">

    <div class="container-fluid mt-5">
        <div class="row">
            <div id="demo" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../../assets/img/B1.jpg" alt="B1" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/B2.jpg" alt="B2" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                        <img src="../../../assets/img/B3.jpg" alt="B3" class="d-block w-100">
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                    <span class="carousel-control-next-icon"></span>
                </button>
            </div>
            
        </div>
    </div>

    <br />
    <div class="container ">
        <div class="row">
            <div class="col-lg-6">
                <div>
                    WelCome <span style="color:red;">{{LoginDetails[0].Name}}</span>
                </div>
            </div>
            <div class="col-lg-6"> </div>
            <div class="col-lg-2 tab_list">

                <ul>
                    <li class="tab_li sp" [class.active]="activeTab === 0" (click)="setActiveTab(0)">Forms</li>
                    <li class="tab_li sp" [class.active]="activeTab === 1" (click)="setActiveTab(1)">Customer List</li>
                    <li class="tab_li sp" [class.active]="activeTab === 2" (click)="setActiveTab(2)">Seizer List</li>
                    <li class="tab_li sp" [class.active]="activeTab === 3" (click)="setActiveTab(3)">Seizer Yards List</li>
                    <li class="tab_li sp" [class.active]="activeTab === 4" (click)="setActiveTab(4)">RTA Agent</li>
                    <li class="tab_li sp" [class.active]="activeTab === 5" (click)="setActiveTab(5)">Absconding vehicle list</li>
                    <li class="tab_li sp" [class.active]="activeTab === 6" (click)="setActiveTab(6)">Blacklist</li>
                    <!--<li class="tab_li sp" [class.active]="activeTab === 5" (click)="setActiveTab(5)">Pawn Broker List</li>-->
                </ul>
            </div>
            <div *ngIf="activeTab === 1" class="col-lg-10">
                <div class="row">
                    <div class="col-lg-7"></div>
                    <div class="col-lg-5">
                        <div>
                            <div class="input-wrapper">
                                <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                                <input [(ngModel)]="seracingdata"
                                       (input)="searchdata()"
                                       class="searchh"
                                       style="width:100%;"
                                       placeholder="Search" />

                                <div *ngIf="seracingdata"
                                     class="close-btn"
                                     (click)="clearSearch()">X</div>
                            </div>
                         
                        </div>
                    </div>
                </div>
               





                <table border="1" cellpadding="10" cellspacing="0">
                    <thead>
                        <tr>
                            <th style="width:130px;">Join Date</th>
                            <th style="width:180px;">Customer Name</th>
                            <th>Customer Address</th>
                            <th>Vehicle No</th>
                            <th>NoticeName</th>
                            <th>pdfpath</th>
                            <!--<th>Block/UnBlock</th>-->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of paginatedData">
                            <td>{{item.CreatedDate | date:'dd-mm-yyyy'}}</td>
                            <td>{{item.BorrowerName}}</td>
                            <td>{{item.BarrowerAddress}}</td>
                            <td>{{item.VehicleRegNo}}</td>
                            <td>{{item.NoticeName}}</td>
                            <td>
                                <a href="{{HomeUrl+item.Formpdfpath}}" target="_blank"> <img src="../../../assets/img/pdflogo1.jpg" alt="pdflogo" style="height:40px;width:40px;" /></a>
                            </td>
                          
                        </tr>
                    </tbody>
                </table>
                <div class="pagination-controls" style="text-align:center;padding-top:10px;">
                    <button class="pagi" (click)="prevPage()" [disabled]="currentPage === 1"><<</button>
                    <span>&nbsp;{{currentPage}}&nbsp;</span>
                    <button class="pagi" (click)="nextPage()" [disabled]="(currentPage * itemsPerPage) >= customerdata.length">>></button>
                </div>

            </div>


            <div *ngIf="activeTab === 2" class="col-lg-10">

                <div class="row">
                    <div class="col-lg-3">
                        <label>Select State &nbsp;</label>
                        <select [(ngModel)]="selectedState" (change)="filterbystate()">
                            <option>All State</option>
                            <option *ngFor="let state of statelist" [value]="state">{{state}}</option>
                        </select>
                    </div>
                    <div class="col-lg-4">
                        <label for="district">Select District  &nbsp;</label>
                        <select id="district" [(ngModel)]="selectedDistrict" (change)="filterByDistricts()">
                            <option value="">All Districts</option>
                            <option *ngFor="let district of districtLists" [value]="district">{{district}}</option>
                        </select>
                    </div>
                    <div class="col-lg-5">
                        <div class="input-wrapper">
                            <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                            <input [(ngModel)]="seracingdata"
                                   (input)="searchdata()"
                                   class="searchh"
                                   style="width:100%;"
                                   placeholder="Search" />

                            <div *ngIf="seracingdata"
                                 class="close-btn"
                                 (click)="clearSearch()">X</div>
                        </div>

                    </div>
                </div>


                <table border="1" cellpadding="10" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>State Name</th>
                            <th>District Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredDataResults1">
                            <!-- Use filtered data -->
                            <td>{{item.Name}}</td>
                            <td>{{item.Mobile}}</td>
                            <td>{{item.EMail}}</td>
                            <td>{{item.StateName}}</td>
                            <td>{{item.DistrictName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="activeTab === 3" class="col-lg-10">
                <div class="row">
                    <div class="col-lg-3">
                        <label>Select State   &nbsp;</label>
                        <select [(ngModel)]="selectedState" (change)="filterbystate1()">
                            <option value="">All State</option>
                            <option *ngFor="let state of statelist1" [value]="state">{{state}}</option>
                        </select>
                    </div>
                    <div class="col-lg-4">
                        <label for="district">Select District   &nbsp;</label>
                        <select id="district" [(ngModel)]="selectedDistrict" (change)="filterByDistricts1()">
                            <option value="">All Districts</option> <!-- Option to show all data -->
                            <option *ngFor="let district of districtList1" [value]="district">{{district}}</option>
                        </select>
                    </div>
                    <div class="col-lg-5">
                        <div class="input-wrapper">
                            <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                            <input [(ngModel)]="seracingdata"
                                   (input)="searchdata()"
                                   class="searchh"
                                   style="width:100%;"
                                   placeholder="Search" />

                            <div *ngIf="seracingdata"
                                 class="close-btn"
                                 (click)="clearSearch()">X</div>
                        </div>
                     
                    </div>
                </div>
             
                <table border="1" cellpadding="10" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>State Name</th>
                            <th>District Name</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredDataResults2">
                            <td>{{item.Name}}</td>
                            <td>{{item.Mobile}}</td>
                            <td>{{item.EMail}}</td>
                            <td>{{item.StateName}}</td>
                            <td>{{item.DistrictName}}</td>
                            <td>{{item.CompanyAddress}}</td>
                        </tr>
                    </tbody>
                </table>


            </div>
            <div *ngIf="activeTab === 4" class="col-lg-10">

                <div class="row">
                    <div class="col-lg-3">
                        <label>Select State &nbsp;  </label>
                        <select [(ngModel)]="selectedState" (change)="filterbystate2()">
                            <option value="">All State </option>
                            <option *ngFor="let state of statelist2" [value]="state">{{state}}</option>
                        </select>
                    </div>
                    <div class="col-lg-4">
                        <label for="district">Select District &nbsp;  </label>
                        <select id="district" [(ngModel)]="selectedDistrict" (change)="filterByDistricts2()">
                            <option value="">All Districts</option> <!-- Option to show all data -->
                            <option *ngFor="let district of districtList2" [value]="district">{{district}}</option>
                        </select>
                    </div>
                    <div class="col-lg-5">
                        <div class="input-wrapper">
                            <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                            <input [(ngModel)]="seracingdata"
                                   (input)="searchdata()"
                                   class="searchh"
                                   style="width:100%;"
                                   placeholder="Search" />

                            <div *ngIf="seracingdata"
                                 class="close-btn"
                                 (click)="clearSearch()">X</div>
                        </div>
                        <!--<div>
                    <input style="width:100%;" class="searchh" [(ngModel)]="seracingdata" placeholder="Search" (input)="filterData()" />
                    <button class="close" (click)="clearSearch()">X</button>
                </div>-->
                    </div>
                </div>


                <table border="1" cellpadding="10" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>State Name</th>
                            <th>District Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredDataResults3">
                            <td>{{item.Name}}</td>
                            <td>{{item.Mobile}}</td>
                            <td>{{item.EMail}}</td>
                            <td>{{item.StateName}}</td>
                            <td>{{item.DistrictName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="activeTab === 5" class="col-lg-10">

                <div class="row">
                    <div class="col-lg-3">
                        <a href="/Abscondingform" class="btn btn-primary" title="Add New Absconding Form">
                            + Add
                        </a>
                    </div>

                    <div class="col-lg-3"></div>

                    <div class="col-lg-6">

                        <div class="input-wrapper">
                            <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                            <input [(ngModel)]="seracingdata"
                                   (input)="searchdata()"
                                   class="searchh"
                                   style="width:100%;"
                                   placeholder="Search" />

                            <div *ngIf="seracingdata"
                                 class="close-btn"
                                 (click)="clearSearch()">X</div>
                        </div>

                    </div>
                </div>

                <div style="overflow-x: auto;">
                    <table border="1" cellpadding="10" cellspacing="0" style="width: 200%; min-width: 700px;">
                        <thead>
                            <tr style="border-bottom:none;">
                                <th class="custom_border">Action</th>
                                <th class="custom_border">Borrower Name</th>
                                <th class="custom_border">Borrower Father Name</th>
                                <th class="custom_border">Borrower Mobile Number</th>
                                <th class="custom_border">Borrower Address</th>
                                <th class="custom_border">
                                    Vehicle Number
                                    <div style="margin-top: 5px;">
                                        <select id="financerFilter" [(ngModel)]="selectedVehicle" (change)="filterData8()">
                                            <option value="">All</option>
                                            <option *ngFor="let financer of uniqueVehicleNumber" [value]="financer">{{financer}}</option>
                                        </select>
                                    </div>
                                </th>
                                <th class="custom_border">Vehicle Engine Number</th>
                                <th class="custom_border">Vehicle Chassis Number</th>
                                <th class="custom_border">
                                    Financer Name
                                    <!-- Dropdown filter for Financer Name -->
                                    <div style="margin-top: 5px;">
                                        <select id="financerFilter" [(ngModel)]="selectedFinancerName" (change)="filterData7()">
                                            <option value="">All</option>
                                            <option *ngFor="let financer of uniqueFinancerNames" [value]="financer">{{financer}}</option>
                                        </select>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filteredDataResults4">
                                <td> <i class="fas fa-trash" (click)="deleteItem(item.AVID)" style="color: red;"></i></td>
                                <td>{{item.BorrowerName}}</td>
                                <td>{{item.BorrowerFatherName}}</td>
                                <td>{{item.BorrowerMobileNumber}}</td>
                                <td>{{item.BorrowerAddress}}</td>
                                <td>{{item.VehicleNumber}}</td>
                                <td>{{item.VehicleEngineNumber}}</td>
                                <td>{{item.VehicleChassisNumber}}</td>
                                <td>{{item.FinancerName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
            <div *ngIf="activeTab === 6" class="col-lg-10">

                <div class="row">
                    <div class="col-lg-3">
                        <a href="/Blacklist" class="btn btn-primary" title="Add New Absconding Form">
                            + Add
                        </a>
                    </div>

                    <div class="col-lg-3"></div>

                    <div class="col-lg-6">

                        <div class="input-wrapper">
                            <i class="fa fa-search search-icon"></i> <!-- Search icon at the start -->

                            <input [(ngModel)]="seracingdata"
                                   (input)="searchdata()"
                                   class="searchh"
                                   style="width:100%;"
                                   placeholder="Search" />

                            <div *ngIf="seracingdata"
                                 class="close-btn"
                                 (click)="clearSearch()">X</div>
                        </div>

                    </div>
                </div>

                <div style="overflow-x: auto;">
                    <table border="1" cellpadding="10" cellspacing="0" style="width: 100%; min-width: 700px;">
                        <thead>
                            <tr>
                                <th class="custom_border">Action</th>
                                <th class="custom_border">Name</th>
                                <th class="custom_border">Address</th>
                                <th class="custom_border">Father</th>
                                <th class="custom_border">Age</th>
                                <th class="custom_border">BlockedBy</th>
                            </tr>
                            <tr>
                                <th> </th>
                                <th>
                                     
                                    <div >
                                        <select style="width: 100%;" id="nameFilter" [(ngModel)]="selectedName" (change)="filterData9()">
                                            <option value="">All</option>
                                            <option *ngFor="let financer of uniqueName" [value]="financer">{{financer}}</option>
                                        </select>
                                    </div>
                                </th>
                                <th>
                                    
                                    <div >
                                        <select style="width: 70%;" id="addressFilter" [(ngModel)]="selectedAddress" (change)="filterData9()">
                                            <option value="">All</option>
                                            <option *ngFor="let financer of uniqueAddress" [value]="financer">{{financer}}</option>
                                        </select>
                                    </div>
                                </th>
                                <th> </th>
                                <th> </th>
                                <th> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filteredDataResults5">
                                <td>
                                    <i class="fas fa-trash" (click)="deleteItem1(item.BLid)" style="color: red; cursor: pointer;"></i>
                                </td>
                                <td>{{item.Name}}</td>
                                <td>{{item.Address}}</td>
                                <td>{{item.Father}}</td>
                                <td>{{item.Age}}</td>
                                <td>{{RegName}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
            <div *ngIf="activeTab === 0" class="col-lg-10">

                <div class="row">

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/RibbonImg.png" alt="ribbon" class="NoticeImage">
                            </div>
                            <div class="NHN">Conciliation Notice</div>
                            <div class="NoticeCardName">Notice for <b>Conciliation Process</b> to Settle Overdue Loan</div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick1()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/RibbonImg.png" alt="ribbon1" class="NoticeImage">
                            </div>
                            <div class="NHN">Final Notice</div>
                            <div class="NoticeCardName">Final Opportunity to Settle Rs._ to <b>Avoid Vehicle Auction.</b></div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick2()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/RibbonImg.png" alt="ribbon2" class="NoticeImage">
                            </div>
                            <div class="NHN">Vehicle Auctioned Notice</div>
                            <div class="NoticeCardName">Vehicle Auctioned for Rs._ <b>Immediate Payment of Remaining Rs._ Required</b></div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6"
                         (click)="onCardClick3()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 3)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/RibbonImg.png" class="NoticeImage" alt="Ribbon Image">
                            </div>
                            <div class="NHN">Pledged Auction Notice</div>
                            <div class="NoticeCardName">Final Notice: Settle Loan Under Pawn Brokers Act 2002 to <b>Avoid Jewelry Auction.</b></div>
                        </div>
                    </div>


                    <div class="col-lg-3 col-xs-6" (click)="onCardClick4()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo" class="NoticeImage">
                            </div>
                            <div class="NHN">No Objection Letter</div>
                            <div class="NoticeCardName">Confirmation of Full Payment for Vehicle Loan – No Objection to Cancel Hire Purchase</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6" (click)="onCardClick8()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo1" class="NoticeImage">
                            </div>
                            <div class="NHN">Form - 35</div>
                            <div class="NoticeCardName">Request for Cancellation of Hire-Purchase/Lease Agreement Endorsement in Vehicle Registration.</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6" (click)="onCardClick5()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo2" class="NoticeImage">
                            </div>
                            <div class="NHN">Agreement Form</div>
                            <div class="NoticeCardName">Authorization for Consultant to <b>Sell Vehicle</b> and Manage Finance Agreement.</div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick6()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo3" class="NoticeImage">
                            </div>
                            <div class="NHN">Authorization Form</div>
                            <div class="NoticeCardName">
                                Request for Issuance of <b>Fresh RC for Vehicle</b>No._ Due to Absconding Owner and Authorization
                                for Inspection Coordination.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6" (click)="onCardClick9()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo4" class="NoticeImage">
                            </div>
                            <div class="NHN">Form - 36</div>
                            <div class="NoticeCardName">Request for Cancellation of Hire-Purchase/Lease Agreement Endorsement in Vehicle Registration.</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6" (click)="onCardClick7()"
                         *ngIf="!LoginDetails || (LoginDetails && LoginDetails[0]?.RoleID === 2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo5" class="NoticeImage">
                            </div>
                            <div class="NHN">Surrender Letter</div>
                            <div class="NoticeCardName">Formal Surrender Notice for Vehicle Loan</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
</div>

