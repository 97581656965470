<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Authorization Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Authorization Form</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>
                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                         
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Secretary Name">Secretary Name<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="SecretaryName" placeholder="Enter Your Secretary Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Secretary Address">Secretary Address<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="SecretaryAddress" placeholder="Enter Your Secretary Address" id="SecretaryAddress" required>
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleRegNo">Vehicle RegNo<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="VehicleRegNo" placeholder="Enter Your Vehicle RegNo" id="VehicleRegNo" required (keyup)="convertToUppercase()">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="ParkedAt">Parked At</label>
                                    <input type="text" formControlName="ParkedAt" placeholder="Enter Your Parked At" id="ParkedAt" required>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="RTAname">RTA Name<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="RTAName" placeholder="Enter Your RTA Name" id="RTAName" required>
                                </div>
                            </div>





                            <div class="form-group">

                                <button class="btn"*ngIf="final6 ==undefined"  (click)="getRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>


                                <button *ngIf="final6 !=undefined" class="btn" (click)="updateRegistration(RegisterForm.value)">
                                    <b>Update</b>
                                </button>
                            </div>

                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
    </div>
