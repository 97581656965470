import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
//import Swal from 'sweetalert2';
import * as html2pdf from 'html2pdf.js';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrl: './preview.component.scss'
})
export class PreviewComponent {
    loginDet: any;
    Fdata: any;
    HomeUrl: any;
    rolename: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.rolename = localStorage.getItem("AgreementNo");
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });


    }

    ngOnInit() {
        this.Getforms();

    }

    downloadPDF() {
        const element = document.getElementById('pdf-content'); // Ensure this ID is in your HTML

        const options = {
            margin: 1,
            filename: 'document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf().from(element).set(options).save();
    }
    // Method to fetch states from the server
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.rolename);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;  // Store the fetched state data
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

}
