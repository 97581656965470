<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
        <li class="breadcrumb-item"><a href="index.html"><i class="fa fa-home"></i> Home</a></li>
        <li class="breadcrumb-item active text-white">refrence</li>
    </ol>
            </h6>
            <!--<ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">About</li>
            </ol>-->
        </div>
    </div>
    <div class="ptb-100">
        <div class="container">
           
            <div class="row">
                <!--<div class="col-lg-2"></div>-->
                <!--<div class="col-lg-8">
            <input class="search" [(ngModel)]="Searchinput" placeholder="Enter Refrence Number" />
        </div>
        <div class="col-lg-1">
            <button class="submt" style="border-radius: 40px;
    width: 7rem; height: 2.5rem;" (click)="getSearchdata()">
                Submit
            </button>
        </div>-->
                <div class="col-lg-8 col-xs-8 col-12">
                    <input class="search form-control" [(ngModel)]="Searchinput" placeholder="Enter Reference Number" />
                </div>
                <div class="col-lg-4 col-xs-4 col-12 text-center text-md-start">
                    <button class="submt btn btn-primary" (click)="getSearchdata()">
                        Submit
                    </button>
                </div>
                <div class="col-lg-1"></div>
            </div>
        </div>
    </div>

</div>
