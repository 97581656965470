<!--9th Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Form-35
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Form-35</li>
            </ol>
        </div>
    </div>
    <div id="pdf-content" style="line-height: 1; font-size: 5px;">

        <br /><br /><div style="margin-top:60px"></div>
        <div id="pdf-content">
            <div class="container pb-0 content">

                <div class="row header">
                    <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center right no-gap">
                        <div class="middle">
                            <h2><b>FORM -35</b></h2>
                            <p><b>[See rule 61 (1)]</b></p>
                            <b>
                                <h6><b>NOTICE OF TERMINATION OF AN AGREEMENT OF HIRE-PURCHASE / LEASE / HYPOTHECATION </b></h6>
                            </b>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-4">
                    </div>
                    <div class="col-sm-4 col-md-4 col-4 text-center">
                    </div>
                </div>
            </div>

            <div class="double-line-container"></div>
            <div class="container-fluid pt-3 content">
                <div class="container">
                    <div class="row no-gap">
                        <div class="col-lg-12 border-top1 border-top2"></div><br />
                        <div class="col-12 text-end">
                            <p style="text-align:start;">
                                To be made in duplicate and in triplicate where the original Registering Authority is different, the duplicate
                                copy and the triplicate copy with the endorsement of the Registering Authority to be returned to the Financier
                                simultaneously on making the entry in the Certificate of Registration and Form-24).
                            </p><br />
                        </div>



                        <div class="col-lg-4 col-4">
                            <p>To</p>
                            <p style="margin-left:68px">THE REGISTERING AUTHORITY</p>
                            <span style="color:red;margin-left:68px"> {{Fdata[0].RegisteringAuthority}} </span>

                        </div>
                    </div><br />
                    <div class="main-content">
                        <!--<h5>Ref:<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>-->
                        <p class="para">
                            We here by declare that the aggreement of Hire-Purchase/Leave/Hypothecation entered into between
                            us has been terminated. We, therefore, request that the note endorsed in the Certificate of Registration
                            of Vehicle No <span style="color:red">{{Fdata[0].VehicleRegNo}}</span> in the respect of the said Aggreement between us be cancelled. The Certificate
                            of registration together with the fee is enclosed.
                        </p>
                        <br />
                        <br />

                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="text-start">
                                <span style="color:red">Date: {{ Fdata[0].RegisteredOwnerSignatureDate | date: 'dd-MM-yyyy' }} </span><br />
                            </div>
                        </div>
                        <div class="col-lg-8 text-left">
                            <div class="text-end">
                                <p>Signature or thumb impression of the Registered Owner</p><br />

                            </div>
                        </div>
                        <div class="col-lg-9"></div>
                        <div class="col-lg-3 text-center">
                            <!--<span style="color:red"> {{formdetails[0].Registeringauthority}} </span>-->
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="text-start">
                                <span style="color:red">Date: {{ Fdata[0].FinancierSignatureDate | date: 'dd-MM-yyyy' }} </span>
                                <p>*strike out which ever is inapplicable</p>
                            </div>
                        </div>
                        <div class="col-lg-7 text-left">
                            <div class="text-end">
                                <p>Signature of the Financier with official seal and address</p><br />
                            </div>
                        </div>

                    </div>

                    <br />



                    <h6 class="text-center boldd">OFFICE ENDORSEMENT</h6><br />
                    <p>
                        Ref. Number __________________ Office of the ______________________________ the cancellation of the entry of an agreement
                        as requested above os recorded in this office Registration record in Form-24 and Registration.
                    </p><br />
                    <p>Certificate on (date) _______________________ </p><br />
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="text-start">
                                <p>Date: ___________ </p><br />
                            </div>
                        </div>
                        <div class="col-lg-6 text-left">
                            <div class="text-end">
                                <p>Signature of the Registering Authority</p><br />

                            </div>

                        </div>
                        <div class="col-lg-9"></div>
                        <div class="col-lg-3 text-center">
                            <!--<span style="color:red">{{formdetails[0].Registeringauthority}}</span>-->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="text-start">
                                <p>To,</p>
                                <p>The Financier</p>
                                ______________________
                            </div>
                        </div>
                        <div class="col-lg-8 text-left">
                            <div class="text-end">

                            </div>
                        </div>
                        <!--</div>

    <div class="row">-->
                        <div class="col-lg-4">
                            <div class="text-start">
                                <div>
                                    <p>The Registering Authority</p>

                                    _________________________
                                </div>
                            </div>
                            <div class="col-lg-8">

                            </div>

                        </div>
                        <p style="text-align:center">(To be sent to both the parties by Registered Post Acknowledgment Due) </p><br />
                        <p> ______________________________________________________________________________________________________________________________ </p><br /><br />

                        <p>
                            Specimen signatures of the Financier are to be obtained in original application for affixing and attestation by
                            the Registering Authority with his office seal in Forms 23 and 24 in such manner that the part of impression
                            of seal or stamp and attestationshall fall upon each signatures.
                        </p><br />

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="text-start">
                                    <p>1. </p>
                                </div>
                            </div>
                            <div class="col-lg-6 text-left">
                                <div class="text-start">
                                    <p>2. </p>
                                </div>
                            </div>
                        </div><br />

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="text-start">
                                    <p>1. </p>
                                </div>
                            </div>
                            <div class="col-lg-6 text-left">
                                <div class="text-start">
                                    <p>2. </p>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>  
                <div class="container"style="margin-top:2px">
                    <div class="col-lg-12 border-top1"></div>
                </div>
            </div>
        </div>

    
       

    </div>
    </div>
    <div class="row">
        <div class="col-lg-8 ">
        </div>
        <div class="col-lg-1 ">
            <button (click)="navigateTOeditform(rolename)" class="pdf"><i class="fa fa-edit"></i> Back  </button>
        </div>
        <div class="col-lg-2  ">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate Pdf  </button>
        </div>


    </div>
