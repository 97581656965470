<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Final Notice
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Final Notice</li>
            </ol>
        </div>
    </div>



    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>


                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Agreement No</label>
                                    <input type="text" formControlName="AgreementNo" placeholder="Enter Your AgreementNo" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>-->
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Borrower Name</label>
                                    <input type="text" formControlName="BorrowerName" placeholder="Enter Your Borrower Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress">Borrower Address</label>
                                    <input type="text" formControlName="BarrowerAddress" placeholder="Enter Your Borrower Address" id="BarrowerAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Barrower Mobile</label>
                                    <input type="text" formControlName="BarrowerMobile" placeholder="Enter Your Barrower Mobile" id="BarrowerAddress" required>
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Surety Name</label>
                                    <input type="text" formControlName="SuretyName" placeholder="Enter Your Surety Name" id="SuretyName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="BarrowerAddress">Surety Address</label>
                                    <input type="text" formControlName="SuretyAddress" placeholder="Enter Your Surety Address" id="SuretyAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="SuretyMobile">Surety Mobile</label>
                                    <input type="text" formControlName="SuretyMobile" placeholder="Enter Your Surety Mobile" id="SuretyAddress" required>
                                </div>
                            </div>
                            <!--<div class="col-lg-4">
                                <div class="form-group">

                                    <label for="DueAmount">Due Amount</label>
                                    <input type="text" formControlName="DueAmount" placeholder="Enter Your Loan Amount" id="LoanAmount" required>
                                </div>
                            </div>-->
                            <!--<div class="col-lg-4">
                                <div class="form-group">

                                    <label for="DueAmount">Financecompanyname</label>
                                    <input type="text" formControlName="Financecompanyname" placeholder="Enter Your Financecompanyname" id="text" required>
                                </div>
                            </div>-->
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="AuctionedDate">Notice Issued Date</label>
                                    <input type="date" formControlName="NoticeIssuedDate" placeholder="Enter Your Notice issued Date" id="NoticeIssuedDate" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="DueAmount">Due Amount</label>
                                    <input type="text" formControlName="DueAmount" placeholder="Enter Your Due Amount" id="DueAmount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="VehicleRegNo">Vehicle Reg No</label>
                                    <input type="text" formControlName="VehicleRegNo" placeholder="Enter Your Vehicle No" id="VehicleRegNo" required (keyup)="convertToUppercase()">
                                </div>
                            </div>


                            <div class="form-group">
                                <!-- If AgreementNo is null, undefined, or empty, show Submit button -->
                                <button *ngIf="aggrementNo==undefined" class="btn" (click)="getRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>

                                <!-- If AgreementNo has a value, show Update button -->
                                <button *ngIf="aggrementNo !==undefined" class="btn" (click)="updateRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>
                            </div>



                            <!--<div class="form-group" (click)="getRegistration(RegisterForm.value)">

                                <button class="btn">
                                    <b>Submit</b>
                                </button>

                            </div>-->
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
    </div>
