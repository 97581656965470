import { Component } from '@angular/core';

@Component({
  selector: 'app-howitisworks',
  standalone: true,
  imports: [],
  templateUrl: './howitisworks.component.html',
  styleUrl: './howitisworks.component.scss'
})
export class HowitisworksComponent {

}
