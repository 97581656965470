import { Component } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { HttpClient } from '@angular/common/http';
//import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
//import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-role-selection',
  templateUrl: './role-selection.component.html',
  styleUrl: './role-selection.component.scss'
})
export class RoleSelectionComponent {

    arr: any = []; transactionID: string = '';
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    profiledata: any;
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    LoginDetails1: any; LoginDetails: any;
    selectedpast: string;
    Lastdonatedate: any;
    Status: boolean = true;
    HomeUrl: any;
    loginDet: any;

    dataResult: any;
    gridview: boolean = true;
    token: any;
    subdata: any;
    PackageName: any;
    PackageDiscount: any;
    PackagePrice: any;
    PackageDuration: any;
    PackageDescription: any;
    PaymentMode: any;
    PackageDiscounts: any;
    PackageNames: any;
    PackagePrices: any;
    PackageDurations: any;
    PackageDescriptions: any;
    PaymentModes: any;
    constructor(private genralservice: GeneralserviceService, public http: HttpClient, public router: Router,) {

        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            
        });
    }

    ngOnInit(): void {
        debugger
       
        // this.bindFormData();


    }


    storeName(name: string) {
        localStorage.setItem('clickedName', name);
        this.router.navigate(['/Registration']); // Navigate to the Registration page
    }

}
