import { Component } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-searchrefrence',
  templateUrl: './searchrefrence.component.html',
  styleUrl: './searchrefrence.component.scss'
})
export class SearchrefrenceComponent {
    serachoutput: any;
    Searchinput: any;
    HomeUrl: any;
    constructor(public generalservice: GeneralserviceService, public http: HttpClient,
        private activatedrout: ActivatedRoute, public route: Router, public fb: FormBuilder) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;            
        });
    }


    ngOnInit(){
      //this.getSearchdata();

    }

    getSearchdata() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.Searchinput);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalservice.Postdata1(url, uploadfile).subscribe(data => {
            debugger
            this.serachoutput = data;
           // this.HomeUrl = "https://financerlawapp.com/apiv1";
            var PDFURL = this.HomeUrl + this.serachoutput[0].Formpdfpath;
            window.open(PDFURL, '_blank');
        })
    }


}
