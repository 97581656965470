<!--8th Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Pledged Auction Notice
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Pledged Auction Notice</li>
            </ol>
        </div>
    </div>
    <br /><br /><div style="margin-top:60px"></div>
    <div id="pdf-content">
        <div class="container pb-0 content">

            <div class="row header">
                <!--<div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center">
                    <div class="middle">
                        <h6><b>BY WAY OF SPEED POST</b></h6>
                    </div>
                </div>-->
                <div class="col-sm-4 col-md-4 col-4">
                    <img src="../../../assets/img/Justies.png" alt="justices" class="justies">
                </div>
                <div class="col-sm-4 col-md-4 col-4 text-center">
                    <div class="middle">
                        <h6><b>BY WAY OF SPEED POST</b></h6>
                    </div>
                    <img src="../../../assets/img/RibbonImg.png" alt="advocate" class="ribbon">
                </div>
                <div class="col-sm-4 col-md-4 col-4 text-center right no-gap">
                    <b>
                        <p class="text-black ">VVS. SATYANARAYANA</p>
                        
                        <p class="text-black ">Advocates</p>
                        <p class="text-black ">Legal Consultants & Solicitors</p>
                        <p class="text-black ">Cell No.9381417022</p>
                    </b>
                </div>
            </div>
        </div>

        <div class="double-line-container"></div>
        <div class="container-fluid pt-3 content">
            <div class="container  bottom-border">
                <div class="row no-gap">
                    <div class="col-lg-12   border-top1"></div>
                    <div class="col-12 text-end border-top2">
                        <p><b>{{ Fdata[0].CreatedDate | date:'dd-MM-yyyy' }}</b></p>

                    </div>
                    <div class="col-lg-4 col-4">
                        <p>To</p>
                        <p>
                            <span style="color:red;">
                                {{Fdata[0].BorrowerName}}<br />

                            </span>
                        </p>
                        <p><span style="color:red;"> {{Fdata[0].BarrowerAddress}}</span></p>
                        <p><span style="color:red;"> {{Fdata[0].BarrowerMobile}}</span></p>

                    </div>
                </div><br />
                <div class="main-content">
                    <h5>Ref:<strong class="text-danger"> {{ rolename}}</strong></h5>
                    <p class="para mt-3">
                        Notice under Telangana pawn brokers Act 2002(Act No. 6 of
                        2002)
                    </p><br />
                    <p>Madam/sir,</p>
                    <p class="para mt-3">
                        This notice is being issued as per instructions and on behalf of
                        <span style="color:red;">
                            {{loginDet[0].CompanyName}},{{loginDet[0].CompanyAddress}}
                        </span> as per section 13 of Telangana pawn brokers act 2002.
                    </p>
                    <p>Loan details are as follows:-</p>
                    <p>Date:- <span style="color:red;">{{Fdata[0].AgreementStartDate  | date:'dd-MM-yyyy'}}</span></p>
                    <p>Loan Amount: <span style="color:red;">{{Fdata[0].LoanAmount}}/-</span></p>
                    <p>Ticket Number: <span style="color:red;">{{Fdata[0].TicketNumber}}</span></p>
                    <p>Total Amount Due: <span style="color:red;">{{Fdata[0].DueAmount}}</span></p>
                    <p>(including interest):</p>
                    <p class="para mt-3">
                        You pledged gold/silver/diamond ornaments/objects to secure
                        the loan. Please note that the value of the pledged jewelry has
                        already exceeded the loan amount and interest.
                    </p>
                    <p class="para mt-3">
                        You are requested to settle the entire outstanding amount,
                        including interest, within 15 days from the date of this notice. This is
                        a final notice, and we expect your immediate response.
                    </p>
                    <p class="para mt-3">
                        If you fail to settle the amount, your jewelry will be auctioned at
                        market value. My client has the authority to auction the pledged
                        jewelry, and further objections will not be entertained.
                    </p>
                    <p class="para mt-3">
                        Please settle the entire outstanding amount along with interest
                        to avoid auction.
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-9">
                        <div class="text-start">
                            <p></p><br />
                            <p></p><br />
                            <p></p><br />
                            <!--<p><span style="color:red;">Address to corresponding:</span> {{loginDet[0].CompanyAddress}}</p><br />-->
                        </div>
                    </div>
                    <div class="col-lg-3 text-left">
                        <div class="text-center">
                            <p>Yours sincerely,</p>
                            <img src="../../../assets/img/Lawyers_Signature-removebg-preview.png" style="height: 60px;" />
                            <p><b>VVS SATYA NARAYANA</b></p>
                            <p>Advocate</p>
                        </div>
                    </div>

                </div>

            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-left">
                        <h6 class="text-danger boldd p-3">Address to correspond: {{loginDet[0].CompanyAddress}}</h6>
                    </div>
                </div>
            </div>

        </div>
        <div class="container-fluid">
            <div class="single-line-container content"></div>
            <div class="container last">

            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-8 ">
    </div>
    <div class="col-lg-1 ">
        <button (click)="navigateTOeditform(Fdata[0].AgreementNo )" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
    </div>
    <div class="col-lg-3">
        <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF  </button>
    </div>
</div>
