<footer class="footer_area pt-70">
    <div class="footer_widget_area">
        <div class="container">
            <div class="row footer_widget_inner">
                <!-- About Widget -->
                <div class="col-lg-3 col-sm-12 col-sm-6">
                    <aside class="f_widget f_about_widget">
                        <img src="../../../assets/img/AppLogo.png" alt="" class="ima">
                        <span class="name">Financier app</span>
                        <p>Welcome to our legal aid app, designed to streamline the process of issuing notice for automobile financiers of consumer default.</p>
                    </aside>
                    <div class="social-links">
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-instagram"></i></a>
                        <a href="#"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                </div>

                <!-- Useful Links -->
                <div class="col-lg-3 col-sm-12 footer-links">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><i class="bi bi-chevron-right"></i> <a href="#">Home</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a [routerLink]="'/AboutUs'">About</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a [routerLink]="'/Subcription'">Subscription</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a [routerLink]="'/how-it-works'">How It Works</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a [routerLink]="'/ContactUs'">Contact</a></li>
                    </ul>
                </div>

                <!-- Services Links -->
                <div class="col-lg-3 col-sm-12 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                        <li><i class="bi bi-chevron-right"></i> <a  href="/Services">Privacy Policy</a></li>
                        <!--<li><i class="bi bi-chevron-right"></i> <a  >Terms and conditions</a></li>-->
                        <li><i class="bi bi-chevron-right"></i> <a  >login</a></li>

                    </ul>
                </div>

                <!-- Social Links -->
                <div class="col-lg-3 col-sm-12 col-md-12 footer-links">
                    <h4 class="follow">Contact Info</h4>
                    <address class="text-white">
                        1-102/1, Gita Bhavan, Nagaram - 500083
                        <br /> <a href="tel:9381417022"><i class="fa fa-phone"></i>+91 9381417022</a>
                        <br /><a href="mailto:financeradvise2024@gmail.com"><i class="fa fa-envelope"></i>financeradvise2024&#64;gmail.com</a>
                    </address>

                </div>
            </div>
        </div>
        <div class="container mt-5">
            
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <div class="copy"> Copyright © 2024 | All rights reserved  by Financiar App  </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="copy footer1" >
                            Powered by  <a href="https://gagri.net/">  <img src="../assets/img/GGL.png" class="  footer-logo" alt="Logo"> </a>
                        </div>
                    </div>
                </div>
           
            <!--<div class="footer_content">
                    <h5 class="copy">
                        <div class="row">
                            <div class="col-lg-6">
                                Copyright ©
                                <script>document.write(new Date().getFullYear());</script> 2024 All rights reserved <i class="fa fa-heart-o" aria-hidden="true"></i><a href="https://gagri.net" target="_blank">
                                    <div class="power">Powered by</div>
                                    <div class="gagri"> Gagri Global IT Services Pvt Ltd</div>
                                </a>
                            </div>
                            <div class="col-lg-6">
                                <a href="https://gagri.net/">  <img src="../assets/img/GGL.png" class="navbar-logo" alt="Logo"> </a>

                            </div>
                        </div>
                    </h5>
            </div>-->
        </div>
    </div>

    <!-- Footer Copyright -->
    <!--<div class="footer_copyright">
        <div class="container">
            <div class="footer_content">
                <div class="footer_left">
                    <h5 class="copy">
                        Copyright ©
                        <script>document.write(new Date().getFullYear());</script> 2024 All rights reserved <i class="fa fa-heart-o" aria-hidden="true"></i><a href="https://gagri.net" target="_blank">
                            <div class="power">Powered by</div>
                            <div class="gagri"> Gagri Global IT Services Pvt Ltd</div>
                        </a>
                       <a href="https://gagri.net/">  <img src="../assets/img/GGL.png" class="navbar-logo" alt="Logo"> </a>
                    </h5>
                </div>
            </div>
        </div>
    </div>-->

</footer>
