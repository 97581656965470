<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Blacklist Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Blacklist Form</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>
                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Name"> Name<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="Name" placeholder="Enter Your Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Address">Address<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="Address" placeholder="Enter Your Address" id="SecretaryAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Father">Father name<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="Father" placeholder="Enter Your Father name" id="SecretaryAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Age">Age<span style="color:red">*</span> </label>
                                    <input type="text" formControlName="Age" placeholder="Enter Your Age" id="SecretaryAddress" required>
                                </div>
                            </div>


                           





                            <div class="form-group">

                                <button class="btn" (click)="getRegistration(RegisterForm.value)">
                                    <b>Submit</b>
                                </button>



                            </div>

                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</div>
