import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
 
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;

    @ViewChild('dragImage', { static: true }) dragImageElement!: ElementRef<HTMLImageElement>;
    @ViewChild('zoomImage', { static: true }) zoomImageElement!: ElementRef<HTMLImageElement>;
    @ViewChild('magnifier', { static: true }) magnifierElement!: ElementRef<HTMLDivElement>;

    scale = 2; // Zoom scale
    isLoggedIn: any;
    subdata: any;
    approvedStatus: number | undefined;
    Subscriptions1: any;
    Subscriptions: any;
    SubscriptionStatus: string;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.LoginDetails = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails);
    }

    ngOnInit() : void {
        this.GetSubscriptions();
  this.GetSubscriptions();

    // Handle the fragment for smooth scrolling
    this.activeroute.fragment.subscribe(fragment => {
      if (fragment) {
        this.scrollToFragment(fragment);
      }
    });
    }

    onConciliationClick() {
        if (!this.generalService.isRegistered()) {
            this.router.navigate(['/Registration']);
        } else if (!this.generalService.isLoggedIn()) {
            this.router.navigate(['/login']).then(() => {
                this.router.navigate(['/home']);
            });
        } else {
            this.router.navigate(['/home']);
        }
    }

    GetSubscriptions() {
        debugger;
        var obj = [{}];
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(obj));
        UploadFile.append("Flag", '4');

        var url = "api/FinancierLaw/FA_subscriptionCRUD";

        this.generalService.PostData2(url, UploadFile).then((data: any) => {
            debugger;
            this.Subscriptions1 = data;

            // Filter subscriptions with ApprovedStatus = 1
            const approvedSubscriptions = this.Subscriptions1.filter((s: any) => s.ApprovedStatus == 1);

            // Store the approvedStatus from the subscription
            if (approvedSubscriptions.length > 0) {
                this.approvedStatus = approvedSubscriptions[0].ApprovedStatus;
            } else {
                this.approvedStatus = 0; // Default value when no approved subscription is found
            }

            if (this.LoginDetails != null && this.LoginDetails.length > 0) {
                const exists = approvedSubscriptions.some((s: any) => s.RegID === this.LoginDetails[0].RegID);

                // Subscription status check
                if (exists) {
                    this.SubscriptionStatus = "Subscribed";
                } else {
                    this.SubscriptionStatus = "Not Subscribed";
                }
            } else {
                this.SubscriptionStatus = "Not Logged In";
            }
        }, err => {
            this.generalService.presentToast("Something went wrong. Please try again later");
        });
    }

    onCardClick(cardNumber: number): void {
        debugger;
        if (this.LoginDetails != undefined) {
            if (this.approvedStatus === 1) {
                this.router.navigate(['/noticeform', cardNumber]);  // Navigate to the navbar page if approvedStatus is 1
            } else {
                this.router.navigate(['/Subcription']);  // Navigate to the subscription page
            }
        } else {
            alert("You are not logged in. Please check your credentials and try again.");
            this.router.navigate(['/Login']);  // Navigate to the login page
        }
    }

private scrollToFragment(fragment: string): void {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

   
}
