<!--5th Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                No Objection Letter
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">No Objection Letter</li>
            </ol>
        </div>
    </div>
    <br /><br /><div style="margin-top:60px"></div>
                <div id="pdf-content">
                    <div class="container-fluid pt-3 content">
                        <div class="container">
                            <div class="row no-gap">
                                <div class="col-lg-12">
                                    <h2 class="surr">
                                        <b>No Objection Letter</b>
                                    </h2>
                                    <br />
                                    <br />
                                </div>

                                <div class="col-lg-12 col-4 border-top1 border-top2">
                                    <br />
                                    <h5>Ref No:<span style="color:red">{{Fdata[0].RefNo}}</span>,</h5>
                                    <div class="col-12 text-start">
                                        <p>
                                            Date:<b><span style="color:red">{{todayDate}}</span>,</b><br />
                                        </p>
                                    </div>
                                    <p>From:</p>
                                    <span>{{loginDet[0].CompanyName}},</span><br />

                                    <span>{{loginDet[0].CompanyAddress}},</span><br />

                                    <p>To:</p>
                                    <span> {{Fdata[0].BorrowerName}},</span><br />
                                    <div>{{Fdata[0].BarrowerAddress}},</div>
                                    <div>{{Fdata[0].BarrowerMobile}},</div>
                                    <p>
                                        Dear Sir,
                                    </p>
                                </div>
                            </div><br />
                            <div class="main-content">
                                <p class="para mt-3">
                                    <b>Sub:</b> Cancellation of Hire purchase Endorsement in
                                    receipt of vehicle bearing No:<span style="color:red">{{Fdata[0].VehicleBearingNumber}}</span> &nbsp;reg.
                                </p>
                                <p class="para mt-3">
                                    We wish to inform you that we have received
                                    payment in full settlement towards the hire purchase
                                    agreement of motor vehicle no.<span style="color:red"> {{Fdata[0].VehicleRegNo}}</span> register in the name
                                    of Sri <span style="color:red"> {{Fdata[0].BorrowerName}}</span>  S/o.<span style="color:red"> {{Fdata[0].FatherName}}</span>  R/o. <span style="color:red"> <b>{{Fdata[0].BarrowerAddress}}</b></span> we have no
                                    objection for cancellation of Hire purchase Endorsement
                                    made in our favor on registration certificate of above said
                                    vehicle. We also forward herewith the Form No. 35 in
                                    duplicate duly completed by us.
                                </p>
                            </div><br />
                            <div class="row">
                                <div class="col-lg-9">
                                    <div>
                                        <p>
                                            Thanking you
                                        </p>
                                        <p>
                                            Encl: Form No.35 in duplicate
                                        </p>
                                        <img src="../../../assets/img/refernceqr.png" alt="reference" style="width: 36%;"/>
                                        <!--<div *ngIf="qrCodeDataUrl">
        <img [src]="qrCodeDataUrl" alt="QR Code" style="width: 36%;" />
    </div>-->
                                        <p style="font-size:15px">Note:To verify the authenticity of Form-35 refer to Scan</p>


                                        <br />
                                    </div>
                                </div>

                                <div class="col-lg-3 ">

                                    <p style="font-size:16px">Yours faithfully</p><br />

                                </div>

                            </div>

                        </div>



                    </div>   
 <div class="container" style="margin-top: 40px;">
                        <div class="col-lg-12 col-4 border-top1 border-top2">

                        </div>
                    </div>
                </div>
</div>

<div class="row">
    <div class="col-lg-8 ">
    </div>
    <div class="col-lg-1 ">
        <button (click)="navigateTOeditform(Fdata[0].RefNo)" class="pdf"><i class="fa fa-edit"></i> Back  </button>
    </div>
    <div class="col-lg-2  ">
        <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate Pdf  </button>
    </div>


</div>
