<!--2nd Form-->

<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container pb-0 content">

        <div class="row header">
            <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center">
                <div class="middle">
                    <h6><b>BY WAY OF SPEED POST</b></h6>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-4">
                <img src="../../../assets/img/Justies.png" alt="justices" class="justies">
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center">

                <img src="../../../assets/img/RibbonImg.png" alt="advocate" class="ribbon">
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center right no-gap">
                <b>
                    <p class="text-black ">VVS. SATYANARAYANA</p>
                    <p class="text-black ">Dr.L.Lakshmi</p>
                    <p class="text-black ">Advocates</p>
                    <p class="text-black ">Legal Consultants & Solicitors</p>
                    <p class="text-black ">Cell No.9849005215</p>
                </b>
            </div>
        </div>
    </div>
    <div class="double-line-container"></div>
    <div class="container-fluid pt-3 content">
        <div class="container  bottom-border">
            <div class="row no-gap">
                <div class="col-lg-12   border-top1"></div>
                <div class="col-12 text-end border-top2">
                    <p><b>{{ Fdata[0].CreatedDate | date:'dd-MM-yyyy' }}</b></p>

                </div>
                <div class="col-lg-4 col-4">
                    <p>To</p>
                    <p>Paramesh Yadav</p>
                    <p>12-1-143, Lalaguda,</p>
                    <p>Secunderabad, 500017</p>
                    <p>(Borrower / Debtor)</p>
                </div>
                <div class="col-lg-4 col-4">

                </div>
                <div class="col-lg-4 col-4">
                    <div class="fromadd">
                        <p>To</p>
                        <p>Kiran Babu</p>
                        <p>6-8-76, Lalaguda,</p>
                        <p>Secunderabad, 500017</p>
                        <p>(Surety / Guarantor)</p>
                    </div>
                </div>
            </div><br />
            <div class="main-content">
                <h5>Ref:<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>
                <p class="para">
                    Final opportunity to settle outstanding amount and release
                    vehicle Madam/Sir Under instruction from and on behalf of my
                    client M/s <span style="color:red">Sri Krishna Financer</span> a notice has been issued to you on
                    dt: <span style="color:red">01-01-2025</span> regarding conciliation and dispute resolution. You
                    have declined the notice. Under a few mandatory circumstances
                    your vehicle has been impounded. As of today your total due to
                    my client is <span style="color:red">Rs 65,000</span> In order to recover this arrears, our client is
                    going to impound the vehicle whose registration number is
                    <span style="color:red">AP10W0101</span> This notice is being sent to give you a final
                    opportunity. Within seven days of receiving this notice, kindly
                    clear all the due amount to my client to have your vehicle
                    released. Otherwise the vehicle will be auctioned by
                    arbitrators.Thank you

                </p>
            </div>
            <div class="row">
                <div class="col-lg-9">
                    <h4 class="text-danger boldd p-3">Address to corresponding: <br /><br />(auto generate financer address)</h4>
                </div>
                <div class="col-lg-3 text-left">
                    <div class="text-center">
                        <p>Yours sincerely,</p><br />
                        <p><b>VVS SATYA NARAYANA</b></p>
                        <p>Advocate</p>
                    </div>
                </div>

            </div>

        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-10 text-left">
                    <h4 class="text-danger boldd p-3">Address to corresponding: 1-102/1, Gita Bhavan, Nagaram - 500083</h4>
                </div>
                <div class="col-lg-2  ">
                    <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Download  </button>
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<br />
<hr />





<!--3rd Form-->

<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container pb-0 content">

        <div class="row header">
            <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center">
                <div class="middle">
                    <h6><b>BY WAY OF SPEED POST</b></h6>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-4">
                <img src="../../../assets/img/Justies.png" alt="justices" class="justies">
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center">

                <img src="../../../assets/img/RibbonImg.png" alt="advocate" class="ribbon">
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center right no-gap">
                <b>
                    <p class="text-black ">VVS. SATYANARAYANA</p>
                    <p class="text-black ">Dr.L.Lakshmi</p>
                    <p class="text-black ">Advocates</p>
                    <p class="text-black ">Legal Consultants & Solicitors</p>
                    <p class="text-black ">Cell No.9849005215</p>
                </b>
            </div>
        </div>
    </div>
    <div class="double-line-container"></div>
    <div class="container-fluid pt-3 content">
        <div class="container  bottom-border">
            <div class="row no-gap">
                <div class="col-lg-12   border-top1"></div>
                <div class="col-12 text-end border-top2">
                    <p><b>{{ Fdata[0].CreatedDate | date:'dd-MM-yyyy' }}</b></p>

                </div>
                <div class="col-lg-4 col-4">
                    <p>To</p>
                    <p>Paramesh Yadav</p>
                    <p>12-1-143, Lalaguda,</p>
                    <p>Secunderabad, 500017</p>
                    <p>(Borrower / Debtor)</p>
                </div>
                <div class="col-lg-4 col-4">

                </div>
                <div class="col-lg-4 col-4">
                    <div class="fromadd">
                        <p>To</p>
                        <p>Kiran Babu</p>
                        <p>6-8-76, Lalaguda,</p>
                        <p>Secunderabad, 500017</p>
                        <p>(Surety / Guarantor)</p>
                    </div>
                </div>
            </div><br />
            <div class="main-content">
                <h5>Ref:<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>
                <p class="para">
                    As per my client instructions M/s <span style="color:red">Sri Krishna Financer</span>, you
                    have entered the finance agreement forthe vehicle registration
                    number <span style="color:red">AP10W0101</span> on dt <span style="color:red">17-07-2024</span> You have breached allthe terms
                    and conditions ofthe contract. Our client hassent you a Invitation
                    for conciliation and Dispute Resolution on Date: <span style="color:red">28-08-2024</span> You declined that too.
                    Also a second notice has been sent to you to release your
                    impounded vehicle by paying the due amount of <span style="color:red">Rs 65,000</span> But
                    you ignored it as well. My client had auctioned your vehicle on dt
                    <span style="color:red">15-09-2024</span> and during auction my clientreceived <span style="color:red">Rs 45,000</span> Also
                    you owe my client <span style="color:red">Rs 20,000</span>.Hence immediately pay this
                    outstanding amountto avoid furtherlegal consequences.
                </p>
            </div>
            <div class="row">
                <div class="col-lg-9"></div>
                <div class="col-lg-3 text-left">
                    <div class="text-center">
                        <p>Yours sincerely,</p><br />
                        <p><b>VVS SATYA NARAYANA</b></p>
                        <p>Advocate</p>
                    </div>
                </div>

            </div>

        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-10 text-left">
                    <h4 class="text-danger boldd p-3">Address to corresponding: 1-102/1, Gita Bhavan, Nagaram - 500083</h4>
                </div>
                <div class="col-lg-2  ">
                    <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Download  </button>
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<br />
<hr />





<!--4th Form-->
<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container-fluid pt-3 content">
        <div class="container">
            <div class="row no-gap">
                <div class="col-lg-12">
                    <h2 class="surr">
                        <b>SURRENDER LETTER FROM THE BORROWER</b>
                    </h2>
                    <br />
                    <br />
                </div>
                <div class="col-lg-12 col-4">
                    <p>To:<span style="color:red">(Auto generates the financer name address)</span></p>
                    <p>Dear Sir/ Madam</p>
                    <p>
                        <b>Subject:</b>
                        Surrender of Vehicle with registration Number
                        <span style="color:red">AP10W0101</span> & manufacturer <span style="color:red">Bajaj</span> & model <span style="color:red">2002</span>.
                    </p>
                    <p>
                        <span style="color:red; margin-left:4rem;">I Kumara Swamy</span> Son/ Daughter of <span style="color:red">Ramanaiah</span>, residing at
                        <span style="color:red">11-12-133, Mehedipatnam,</span> hereby submit the following
                        statement:
                    </p>
                </div>
            </div><br />
            <div class="main-content">
                <p class="para">
                    I acknowledge that I have acquired a registered vehicle No.
                    <span style="color:red;">AP10W0101</span> through your finance scheme. However, due to
                    unforeseen circumstances, I express my inability to meet the
                    installment payments due. Therefore, I am voluntarily
                    surrendering the aforementioned vehicle to your finance
                    company. I affirm that this decision is made willingly and
                    without any external pressure. I acknowledge the rights vested
                    in the finance company as outlined in the composite loan
                    agreement for loan and guarantee, which I have violated by
                    defaulting on the scheduled repayments for the vehicle loan.
                </p>
                <p class="para">
                    Hence, I kindly request your cooperation in accepting the
                    surrendered vehicle.
                </p>
                <p class="para">
                    Thank you for your understanding and cooperation.
                </p>
            </div><br />
            <div class="row">
                <div class="col-lg-3">
                    <div class="text-center">
                        <p>Yours sincerely,</p><br />
                        <p>Date:</p>
                    </div>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-left">
                    <div class="text-center">
                        <p>Name</p><br />
                        <p>Sign</p>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<br />
<hr />




<!--5th Form-->
<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container-fluid pt-3 content">
        <div class="container">
            <div class="row no-gap">
                <div class="col-lg-12">
                    <h2 class="surr">
                        <b>No Objection Letter</b>
                    </h2>
                    <br />
                    <br />
                </div>

                <div class="col-lg-12 col-4 border-top1 border-top2">
                    <h5>Ref No.________<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>
                    <div class="col-12 text-start">
                        <p>
                            <b>Date:</b><br />
                        </p>
                    </div>
                    <p>From:______</p>
                    <span style="color:red;">(financer namemust be auto generated)</span>
                    <p>To:______</p>
                    <p>
                        Dear Sir,
                    </p>
                </div>
            </div><br />
            <div class="main-content">
                <p class="para">
                    <b>Sub:</b> Cancellation of Hire purchase Endorsement in
                    receipt of vehicle bearingNo:___________ reg.
                </p>
                <p class="para">
                    We wish to inform you that we have received
                    payment in full settlement towards the hire purchase
                    agreement of motor vehicle no. ______ register in the name
                    of Sri ______________ S/o. ___________ R/o. _____________we have no
                    objection for cancellation of Hire purchase Endorsement
                    made in our favor on registration certificate of above said
                    vehicle. We also forward herewith the Form No. 35 in
                    duplicate duly completed by us.
                </p>
            </div><br />
            <div class="row">
                <div class="col-lg-6">
                    <div class="text-center">
                        <p class="para">
                            Thanking you
                        </p>
                        <p class="para">
                            Encl: Form No.35 in duplicate
                        </p>
                        <img src="../../../assets/img/pdf.PNG" alt="assetsImg" style="margin-left: -16rem;" />
                        <p class="para">Note:</p>
                        <p class="para">To verify the authenticity of Form -35 refer to Scan</p>
                        <br />
                    </div>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-left">
                    <div class="text-center">
                        <p>Yours faithfully</p><br />
                    </div>
                </div>

            </div>

        </div>


    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<br />
<hr />




<!--6th Form-->
<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container-fluid pt-3 content">
        <div class="container">
            <div class="row no-gap">
                <div class="col-lg-12">
                    <h2 class="surr">
                        <b>AUTHORIZATION LETTER</b>
                    </h2>
                    <br />
                    <br />
                </div>

                <div class="col-lg-12 col-4 border-top1 border-top2"><br/>
                    <h5>Ref No.________<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>
                    <div class="col-12 text-start">
                        <p><span style="color:red;">Finance Business Name and address (must be auto generated)</span><br /></p>
                    </div>
                    <p>To,</p>
                    <p>
                        The Secretary_______________
                    </p>
                    <p>
                        <b>Sub:</b> Issue of fresh RC
                    </p>
                    <p>
                        Vehicle no: ____________
                    </p>
                    <p>
                        Respected Sir,
                    </p>
                </div>
            </div><br />
            <div class="main-content">
                <p class="para">
                    I am the proprietor of M/s _______, ___________<span style="color:red;">(addressmust be auto generated)</span>.
                    We have applied for fresh RC for the above mentioned vehicle no as the owner is absconding. Hence we request you to issue FRC in
                    favor of financier.
                </p>
                <p class="para">
                    The vehicle is under my possession, parked at
                    _____. During inspection the vehicle will be
                    produced. We request that Mr._____ our outstanding
                    field worker, be authorized to coordinate with you on
                    our behalf to complete ofthistask.
                </p>
            </div><br />
            <div class="row">
                <div class="col-lg-6">
                    <div class="text-center">
                        <p class="para">
                            Thank you,
                        </p>
                        <img src="../../../assets/img/qrauth.PNG" alt="qrauth" />
                        <p class="para">Note:</p>
                        <p class="para">Toverify the authenticityof Form-36 refer to Scan</p>
                        <br />
                    </div>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-left">
                    <div class="text-center">
                        <p>Yours faithfully</p><br />
                    </div>
                </div>

            </div>

        </div>


    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<br />
<hr />




<!--7th Form-->
<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container-fluid pt-3 content">
        <div class="container">
            <div class="row no-gap">
                <div class="col-lg-12 ">
                    <h2 class="surr">
                        <b class="bottom-border1">Agreement</b>
                    </h2>
                    <br />
                    <br />
                </div>

                <div class="col-lg-12 col-4 border-top1 border-top2">
                    <p><span style="color:red;">To:</span></p>
                    <p><span style="color:red;">BhihariAuto Finance Corporation</span><p>
                    <p><span style="color:red;">10-2-785,Radha Complex,Hyderabad.</span></p>
                    <br />
                    <br />
                    <h5>Ref No.________<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>

                    <p>
                        <b>Sub:</b> Agreementforsaleof vehicle Madam/Sir
                    </p>
                </div>
            </div><br />
            <div class="main-content">
                <p class="para">
                    We <span style="color:red;">Ramdev Auto Consultant,</span> Add: <span style="color:red;">
                        6-1-122, Nampally,
                        Hyderabad,
                    </span> (second sales dealer) have obtained permission
                    fromyou to take possession of yourfinance hypodication vehicle
                    no <span style="color:red;"> AP10W1010</span> and sell it within <span style="color:red;">30</span> days dated from <span style="color:red;">16-07-2024</span>.
                </p>
                <p class="para">
                    We agree to facilitate a finance agreement between you and
                    buyer for clearance of amount due to you, handle traffic /RTA
                    documentary/completion/clearance matters related to the
                    vehicle.
                </p>
                <p class="para">
                    This agreement is subjected to timely completion of sale
                    purchase. In case the sale is not completed on time, we will pay
                    you reasonable interest with your consent.
                </p>
                <p class="para">
                    You have the authority to terminate this agreement at any
                    time.
                </p>
                <p class="para">
                    By signing below, we agree to terms of this agreement for
                    thebenefit of both parties.
                </p>
            </div><br />
            <div class="row">
                <div class="col-lg-6">
                    <div class="text-center">
                        <p class="para">
                            Thank you,
                        </p>
                        <p class="para">From <span style="color:red;"> Ramdev Auto Consultant</span></p>
                        <p class="para">Date: <span style="color:red;">16-06-2024</span></p>
                        <br />
                    </div>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-left">
                    <div class="text-center">
                        <p>Sign</p><br />
                    </div>
                </div>

            </div>

        </div>


    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<br />
<hr />





<!--8th Form-->

<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container pb-0 content">

        <div class="row header">
            <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center">
                <div class="middle">
                    <h6><b>BY WAY OF SPEED POST</b></h6>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-4">
                <img src="../../../assets/img/Justies.png" alt="justices" class="justies">
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center">
                <img src="../../../assets/img/RibbonImg.png" alt="advocate" class="ribbon">
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center right no-gap">
                <b>
                    <p class="text-black ">VVS. SATYANARAYANA</p>
                    <p class="text-black ">Dr.L.Lakshmi</p>
                    <p class="text-black ">Advocates</p>
                    <p class="text-black ">Legal Consultants & Solicitors</p>
                    <p class="text-black ">Cell No.9849005215</p>
                </b>
            </div>
        </div>
    </div>

    <div class="double-line-container"></div>
    <div class="container-fluid pt-3 content">
        <div class="container  bottom-border">
            <div class="row no-gap">
                <div class="col-lg-12   border-top1"></div>
                <div class="col-12 text-end border-top2">
                    <p><b>{{ Fdata[0].CreatedDate | date:'dd-MM-yyyy' }}</b></p>

                </div>
                <div class="col-lg-4 col-4">
                    <p><span style="color:red;">To Vinod Kumar</span></p>
                    <p><span style="color:red;">17-2-222, Lalbazar,</span></p>
                    <p><span style="color:red;">Hyderabad-500072,</span></p>
                </div>
            </div><br />
            <div class="main-content">
                <h5>Ref:<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>
                <p class="para">
                    Notice under Telangana pawn brokers Act 2002(Act No. 6 of
                    2002)
                </p>
                <p>Madam/sir,</p>
                <p class="para">
                    This notice is being issued as per instructions and on behalf of
                    <span style="color:red;">
                        Paramesh Lal Pawan Brokers, 19-7-55, Chilkalguda, Secunderabad
                        (auto generate)
                    </span> as per section 13 of Telangana pawn brokers act 2002.
                </p>
                <p>Loandetailsare asfollows:-</p>
                <p>Date:- <span style="color:red;">16-07-2022</span></p>
                <p>Loan Amount: <span style="color:red;">80,000/-</span></p>
                <p>TicketNumber: <span style="color:red;">7313</span></p>
                <p>TotalAmountDue: <span style="color:red;">1,20,000/-</span></p>
                <p>(including interest):</p>
                <p class="para">
                    You pledged gold/silver/diamond ornaments/objects to secure
                    the loan. Please note that the value of the pledged jewelry has
                    already exceeded the loan amount and interest.
                </p>
                <p class="para">
                    You are requested to settle the entire outstanding amount,
                    including interest, within <span style="color:red;">15</span> daysfrom the date of this notice. Thisis
                    a final notice, and we expect yourimmediate response.
                </p>
                <p class="para">
                    If you fail to settle the amount, your jewelry will be auctioned at
                    market value. My client has the authority to auction the pledged
                    jewelry, and further objections will not be entertained.
                </p>
                <p class="para">
                    Please settle the entire outstanding amount along with interest
                    to avoid auction.
                </p>
            </div>
            <div class="row">
                <div class="col-lg-9">
                    <div class="text-start">
                        <p></p><br />
                        <p></p><br />
                        <p></p><br />
                        <p><span style="color:red;">Address to corresponding:</span></p><br />
                    </div>
                </div>
                <div class="col-lg-3 text-left">
                    <div class="text-center">
                        <p>Yours sincerely,</p><br />
                        <p><b>VVS SATYA NARAYANA</b></p>
                        <p>Advocate</p>
                    </div>
                </div>

            </div>

        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-10 text-left">
                    <h4 class="text-danger boldd p-3">Paramesh Lal Pawan Brokers, 19-7-55, Chilkalguda, Secunderabad</h4>
                </div>
                <div class="col-lg-2  ">
                    <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Download  </button>
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<br />
<hr />




<!--9th Form-->

<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container pb-0 content">

        <div class="row header">
            <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center right no-gap">
                <div class="middle">
                    <h2><b>FORM -35</b></h2>
                    <p><b>[See rule 61 (1)]</b></p>
                    <b>
                        <h6><b>NOTICE OF TERMINATION OF AN AGREEMENT OF HIRE-PURCHASE / LEASE / HYPOTHECATION </b></h6>
                    </b>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-4">
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center">
            </div>
        </div>
    </div>

    <div class="double-line-container"></div>
    <div class="container-fluid pt-3 content">
        <div class="container">
            <div class="row no-gap">
                <div class="col-lg-12 border-top1 border-top2"></div>
                <div class="col-12 text-end">
                    <p style="text-align:start;">
                        To be made in duplicate and in triplicate where the original Registering Authority is diffrent, the duplicate
                        copy and the triplicate copy with the endorsement of the Registering Authority to be returned to the Financier
                        simultaneously on making the entry in the Certificate of Registration and Form-24).
                    </p><br />
                </div>
                <div class="col-lg-4 col-4">
                    <p>To</p>
                    <p class="para">THE REGISTERING AUTHORITY</p>
                    <p class="para">_______________________</p>
                    <p class="para">_______________________</p>
                </div>
            </div><br />
            <div class="main-content">
                <!--<h5>Ref:<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>-->
                <p class="para">
                    We here by declare that the aggrement of Hire-Purchase/Leave/Hypothecation entered into between
                    us has been terminated. We, therefore, request that the note endorsed in the Certificate of Registration
                    of Vehicle No ____________ in the respect of the said Aggreement between us be cancelled. The Certificate
                    of registration together with the fee is enclosed.
                </p>
                <br />
                <br />
                <br />
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="text-start">
                        <p>Date:__________</p><br />
                    </div>
                </div>
                <div class="col-lg-8 text-left">
                    <div class="text-end">
                        <p>Signature or thumb impression of the Registered Owner</p><br />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="row">
                <div class="col-lg-4">
                    <div class="text-start">
                        <p>Date:__________</p>
                        <p>*strike out which ever is inapplicable</p>
                    </div>
                </div>
                <div class="col-lg-8 text-left">
                    <div class="text-end">
                        <p>Signature of the Financier with official seal and address</p><br />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <h6 class="text-center boldd">OFFICE ENDORSEMENT</h6><br />
            <p>
                Ref. Number __________ Office of the __________the cancellation of the entry of an agreement
                as requested above os recorded in this office Registration record in Form-24 and Registration.
            </p><br />
            <p>Certificate on ___________(date)</p><br />
            <div class="row">
                <div class="col-lg-4">
                    <div class="text-start">
                        <p>Date:_________</p><br />
                    </div>
                </div>
                <div class="col-lg-8 text-left">
                    <div class="text-end">
                        <p>Signature of the Registering Authority</p><br />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="text-start">
                        <p>To,</p>
                        <p>The Financier</p>
                    </div>
                </div>
                <div class="col-lg-8 text-left">
                    <div class="text-end">
                        <p>_________________________</p>
                        <p>_________________________</p>
                        <p>_________________________</p>
                        <p>_________________________</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="text-start">
                        <p>The Registering Authority</p>
                    </div>
                </div>
                <div class="col-lg-8 text-left">
                    <div class="text-end">
                        <p>_________________________</p>
                        <p>_________________________</p>
                        <p>_________________________</p>
                        <p>_________________________</p><br />
                    </div>
                </div>
                <p class="text-center">(To be sent to both the parties by Registered Post Acknowledgment Due)</p><br />
                <p>_______________________________________________________________________________________________</p><br /><br />
                <p>
                    Specimen signatures of the Financier are to be obtained in original application for affixing and attestation by
                    the Registering Authority with his office seal in Forms 23 and 24 in such manner that the part of impression
                    of seal or stamp and attestationshall fall upon each signatures.
                </p>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="text-start">
                        <p>1. </p>
                    </div>
                </div>
                <div class="col-lg-6 text-left">
                    <div class="text-start">
                        <p>2. </p><br /><br />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="text-start">
                        <p>1. </p>
                    </div>
                </div>
                <div class="col-lg-6 text-left">
                    <div class="text-start">
                        <p>2. </p>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<hr />




<!--10th Form-->

<br /><br /><div style="margin-top:60px"></div>
<div id="pdf-content">
    <div class="container pb-0 content">

        <div class="row header">
            <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center right no-gap">
                <div class="middle">
                    <h2><b>FORM -36</b></h2>
                    <p><b>[See rule 61 (2)]</b></p>
                        <h6>APPLICATION FOR ISSUE OF A FRESH CERTIFICATE OF REGISTRATION IN THE NAME OF THE FINANCIER</h6>
                    <br/><br/>
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-4">
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center">
            </div>
        </div>
    </div>

    <div class="double-line-container"></div>
    <div class="container-fluid pt-3 content">
        <div class="container">
            <div class="row no-gap">
                <div class="col-lg-12 border-top1 border-top2   ">
                    <p>To</p>
                </div>
                <div class="col-12 text-start">
                    <p style="margin-left:18px;">THE REGISTRATION AUTHORITY,</p>
                    <p> ___________________________</p><br />
                </div>
            </div><br />
            <div class="main-content">
                <!--<h5>Ref:<strong class="text-danger">Dynamic {{ Fdata[0].AgreementNo }}</strong></h5>-->
                <p>
                    I/We ____________(Financier) have taken possession of motor vehicle No. __________
                    Make ____________ Model ___________ Owing to the default of the Registered Owner
                    (name) _______________, (Address)______________ <br/><br/>
                </p>
                <p>Under the provisions of the agreement of hire purchase/lease/hypothecation</p>
                <p>1) The Certificate of Registration of the said vehicle is surrendered here with</p>
                <p>2) The Registered owner has refused to deliver the Certificate of Registration to me / us.</p>
                <p>3) The Registered owner is absconding</p><br/>
                <p>I/We request you to cancel the Certificate and issue a fresh certificate of registration in my/our name.</p><br/>
                <p>I/We enclose a fee of Rs.____________________________________________________________________</p><br/>
                <br />
                <br />
                <br />
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="text-start">
                        <p>Date:__________</p><br />
                    </div>
                </div>
                <div class="col-lg-8 text-left">
                    <div class="text-end">
                        <p>Signature of the Financier</p><br />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-start">
                        <p>Specimen Signature of the Financier</p>
                        <p>1.________________________</p>
                        <p>2.________________________</p><br />
                    </div>
                </div>
                <div class="col-lg-12 text-left">
                    <div class="text-start">
                        <p>Copy to the Original registering authority.</p>
                        <p>Strike out whenever is inapplcable.</p>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
        </div>
    </div>
    <div class="container-fluid">
        <div class="single-line-container content"></div>
        <div class="container last">

        </div>
    </div>
</div>
<br />
<br />
<hr />
