import { Component } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { HttpClient } from '@angular/common/http';
//import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
//import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {

  //  public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    profiledata: any;
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    LoginDetails1: any; LoginDetails: any;
    selectedpast: string;
    Lastdonatedate: any;
    Status: boolean = true;
    HomeUrl: any;
    loginDet: any;
  
    dataResult: any;
    gridview: boolean = true;
    token: any;
    subdata: any;
    PackageName: any;
    PackageDiscount: any;
    PackagePrice: any;
    PackageDuration: any;
    PackageDescription: any;
    PaymentMode: any;
    constructor(private genralservice: GeneralserviceService, public http: HttpClient,  public router: Router,) {

        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            this.GetProduct();
        });
    }

    ngOnInit(): void {
        debugger
        this.GetProduct();
        // this.bindFormData();


    }



    GetProduct() {
        debugger;
        this.arr = [];
        this.arr.push({
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/FinancierLaw/FA_PackageCRUD";
        this.genralservice.Postdata1(url, UploadFile).subscribe(data => {
            this.subdata = data;
            this.PackageName = data[0].PackageName;
            this.PackageDiscount = data[0].PackageDiscount;
            this.PackagePrice = data[0].PackagePrice;
            this.PackageDuration = data[0].PackageDuration;
            this.PackageDescription = data[0].PackageDescription;
            this.PaymentMode = data[0].PaymentMode;
           
            // Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }



    GetProduct1() {
        debugger

        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.genralservice.GetData(url).subscribe((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                //RegID: this.loginDetails[0].RegID,
                //TokenID: this.loginDetails[0].TokenID
              //  Price: 0.00
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/FinancierLaw/FA_PackageCRUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {

                    debugger
                    // Assuming data is an array, assign it directly
                    this.dataResult = data;

                    // The rest of your code...
                },
                (err) => {
                    this.genralservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });

    }

}
