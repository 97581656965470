import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
//import Swal from 'sweetalert2';
import * as html2pdf from 'html2pdf.js';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'app-buyerform',
    templateUrl: './buyerform.component.html',
    styleUrl: './buyerform.component.scss'
})
export class BuyerformComponent {
    loginDet: any;
    Fdata: any;
    HomeUrl: any;
    todayDate: any;
    surrenderform: any; pdf: any;
    imagefile2: string | ArrayBuffer;
    TypeImage: string | ArrayBuffer;
    seller: any; buyer: any;
    Vehicle: any;
    currentDate: any;
    isGeneratingPDF: boolean = false;
    isGeneratingbutton: boolean = true;
    sellerSignatureUrl: string | null = null;
    buyerSignatureUrl: string | null = null;
    witnessSignatureUrl: string | null = null;
    vehiclePhotoUrl: string | null = null;
    sellerImageUrl: string | null = null;
    buyerImageUrl: string | null = null;
    witnessImageUrl: string | null = null;
    WitnessImage2Url: string | null = null;

    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.sellerSignatureUrl = localStorage.getItem('signature_exterior');
        this.buyerSignatureUrl = localStorage.getItem('signature_buyer');
        this.witnessSignatureUrl = localStorage.getItem('signature_witness');
        this.vehiclePhotoUrl = localStorage.getItem('signature_vehicle');

        this.sellerImageUrl = localStorage.getItem('Seller_photo');
        this.buyerImageUrl = localStorage.getItem('Buyyer_photo');
        this.witnessImageUrl = localStorage.getItem('Witness_photo1');
        this.WitnessImage2Url = localStorage.getItem('Witness_photo2');


        this.seller = JSON.parse(localStorage.getItem("seller"));
        this.buyer = JSON.parse(localStorage.getItem("buyer"));
        this.Vehicle = JSON.parse(localStorage.getItem("Vehicle"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const yyyy = today.getFullYear();

        this.todayDate = `${dd}-${mm}-${yyyy}`;

        if (!this.loginDet || !this.loginDet[0] || !this.loginDet[0].RegID) {
            this.router.navigate(['/Login']);
            return;
        }

    }

    ngOnInit() {
        this.Getforms();
        const today = new Date();
        this.currentDate = today.toLocaleDateString('en-GB');
    }

    downloadPDF() {
        this.isGeneratingPDF = true;
        this.isGeneratingbutton = false;
        const element = document.getElementById('pdf-content');
        if (!element) {
            console.error('Element not found');
            return;
        }

        // Your logic to generate PDF
        setTimeout(() => {
            // Set it back to false after PDF is generated
            this.isGeneratingPDF = false;
        }, 5000);  // Adjust the timeout duration based on how long PDF generation takes

        const originalStyle = {
            fontSize: element.style.fontSize,
            lineHeight: element.style.lineHeight,
            padding: element.style.padding
        };

        element.style.fontSize = '14px';
        element.style.lineHeight = '1';
        element.style.padding = '0';

        const paragraphs = element.getElementsByTagName('p');
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i].style.margin = '0';
            paragraphs[i].style.padding = '0';
        }

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const seconds = String(today.getSeconds()).padStart(2, '0');

        const dateTimeString = `${dd}-${mm}-${yyyy}-${hours}:${minutes}:${seconds}`;
        const filename = `Buyerform_${dateTimeString}.pdf`;

        const options = {
            margin: [0, 0.5, 0.5, 0.5],
            filename: filename,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };

        html2pdf()
            .from(element)
            .set(options)
            .save()
            .then(() => {
                element.style.fontSize = originalStyle.fontSize;
                element.style.lineHeight = originalStyle.lineHeight;
                element.style.padding = originalStyle.padding;

                for (let i = 0; i < paragraphs.length; i++) {
                    paragraphs[i].style.margin = '';
                    paragraphs[i].style.padding = '';
                }

                alert('Your pdf has been downloaded successfully to your downloads folder.');
            });

        html2pdf().from(element).set(options).toPdf().outputPdf('blob').then((pdfBlob) => {
            const file = new File([pdfBlob], filename, { type: 'application/pdf' });
            this.autoUpload(file);
        });
    }




    autoUpload(file: File) {
        debugger
        const reader = new FileReader();
        reader.onload = () => {
            this.imagefile2 = reader.result;
            this.TypeImage = this.imagefile2;
        };
        reader.readAsDataURL(file);

        const selectedFile = file;
        const idxDot = selectedFile.name.lastIndexOf(".") + 1;
        const extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png" || extFile === "pdf" || extFile === "webp") {
            const UploadFile = new FormData();
            UploadFile.append("pdf", file);
            const url = 'api/FinancierLaw/Uploadformpdf';
            this.generalService.Postdata1(url, UploadFile).subscribe((data: any) => {
                if (data != null) {
                    debugger
                    this.pdf = data;
                    this.Insertpdfpath()
                }
            });
        } else {
            alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
        }
    }

    Insertpdfpath() {
        debugger;

        // Retrieve the images from localStorage
        const sellerSignature = localStorage.getItem('signature_exterior');
        const buyerSignature = localStorage.getItem('signature_buyer');
        const witnessSignature = localStorage.getItem('signature_witness');
        const vehiclePhoto = localStorage.getItem('signature_vehicle');

        // Construct the PDF data with seller, buyer, vehicle info and the images
        const pdfData = [{
            ...this.seller,
            ...this.buyer,
            ...this.Vehicle,
            SellerSignature: sellerSignature,  // Add seller signature image
            BuyerSignature: buyerSignature,    // Add buyer signature image
            WitnessSignature: witnessSignature, // Add witness signature image
            VehiclePhoto: vehiclePhoto         // Add vehicle photo
        }];

        // Create FormData to send the request
        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(pdfData));
        uploadfile.append('Flag', "1");

        // URL for the API
        const url = "api/FinancierLaw/FA_SecondSaleForms_CRUD";

        // Post the data to the server
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                if (data === 'SUCCESS') {
                    this.generalService.ShowAlert('Success', 'PDF download successful', 'success');
                }
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );

        // Navigate back to home page
        this.router.navigate(['/1home']);
    }

    // Method to fetch states from the server
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.surrenderform);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;  // Store the fetched state data
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    navigateTOeditform(aggrementno: any) {

        this.router.navigate(['/noticeform7'], { queryParams: { AggrementNo: aggrementno } });
        localStorage.setItem('notice7', aggrementno);
    }
}


