<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Vehicle Auctioned Notice
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Vehicle Auctioned Notice</li>
            </ol>
        </div>
    </div>
    <br /><br /><div style="margin-top:60px"></div>
                <div id="pdf-content">
                    <div class="container pb-0 content">

                        <div class="row header">
                            <div class="col-sm-4 col-md-4 col-4 col-lg-12 text-center">
                                <div class="middle">
                                    <h6><b>BY WAY OF SPEED POST</b></h6>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-4 col-4">
                                <img src="../../../assets/img/Justies.png" alt="justices" class="justies">
                            </div>
                            <div class="col-sm-4 col-md-4 col-4 text-center">

                                <img src="../../../assets/img/RibbonImg.png" alt="advocate" class="ribbon">
                            </div>
                            <div class="col-sm-4 col-md-4 col-4 text-center right no-gap">
                                <b>
                                    <p class="text-black ">VVS. SATYANARAYANA</p>
                                    <p class="text-black ">Advocates</p>
                                    <p class="text-black ">Legal Consultants & Solicitors</p>
                                    <p class="text-black ">Cell No.9381417022</p>
                                </b>
                            </div>
                        </div>
                    </div>
                    <div class="double-line-container"></div>
                    <div class="container-fluid pt-3 content">
                        <div class="container  bottom-border">
                            <div class="row no-gap">
                                <div class="col-lg-12   border-top1"></div>
                                <div class="col-12 text-end border-top2">
                                    <p><b> Date: {{ Fdata[0].CreatedDate | date:'dd-MM-yyyy' }}</b></p>

                                </div>
                                <div class="col-lg-4 col-4">
                                    <p>To</p>
                                    <p><b>{{Fdata[0].BorrowerName}}</b></p>
                                    <p>{{Fdata[0].BarrowerAddress}}</p>
                                    <p>{{Fdata[0].BarrowerMobile}}</p>
                                    <p>(Borrower / Debtor)</p>
                                </div>
                                <div class="col-lg-3 col-4">
                                </div>
                                <div class="col-lg-5 col-4">
                                    <div class="fromadd">
                                        <p>To</p>
                                        <p>
                                            <b>{{Fdata[0].SuretyName}}</b>
                                        </p>
                                        <p>{{Fdata[0].SuretyAddress}}</p>
                                        <p>{{Fdata[0].SuretyMobile}}</p>

                                        <p>(Surety / Guarantor)</p>
                                    </div>
                                </div>
                            </div><br />
                            <div class="main-content">
                                <h5>Ref:<strong class="text-danger"> {{ aggrementno}}</strong></h5>
                                <p class="para mt-3">
                                    As per my client instructions M/s <span style="color:red">{{loginDet[0].CompanyName}}, {{loginDet[0].CompanyAddress}}</span>, you
                                    have entered the finance agreement for the vehicle registration
                                    number <span style="color:red">{{Fdata[0].VehicleRegNo}}</span> on dt <span style="color:red">17-07-2024</span> You have breached all the terms
                                    and conditions of the contract. Our client has sent you a Invitation
                                    for conciliation and Dispute Resolution on Date: <span style="color:red">{{Fdata[0].DisputeResolutionDate  | date:'dd-MM-yyyy'}}</span> You declined that too.
                                    Also a second notice has been sent to you to release your
                                    impounded vehicle by paying the due amount of <span style="color:red">Rs. {{Fdata[0].DueAmount}}/-</span> But
                                    you ignored it as well. My client had auctioned your vehicle on dt
                                    <span style="color:red">{{Fdata[0].VehicleActionedDate  | date:'dd-MM-yyyy'}}</span> and during auction my client received <span style="color:red">Rs. {{Fdata[0].AuctionReceivedMoney}}/-</span> Also
                                    you owe my client <span style="color:red">Rs. {{Fdata[0].OutstandingAmount}}/-</span>.Hence immediately pay this
                                    outstanding amount to avoid further legal consequences.
                                </p>
                            </div>
                            <div id="pdf-content">
                                <div class="row footerrr">
                                    <div class="col-lg-8"></div>
                                    <div class="col-lg-4">
                                        <div class="text-center signature">
                                            <p>Yours sincerely,</p>
                                            <img src="../../../assets/img/Lawyers_Signature-removebg-preview.png" style="height: 60px;" /><br />
                                            <p class="name"><b>VVS SATYA NARAYANA</b></p>
                                            <p class="name">Advocate</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <h6 class="text-danger boldd p-3">Address to correspond: {{loginDet[0].CompanyAddress}}</h6>
                            </div>
                        </div>
                    </div>
                </div>


    

</div>
<div class="row">
    <div class="col-lg-8 ">
    </div>
    <div class="col-lg-1 ">
        <button (click)="navigateTOeditform(Fdata[0].AgreementNo )" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
    </div>
    <div class="col-lg-2  ">
        <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate Pdf  </button>
    </div>


</div>
