import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
declare var $: any;
@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    modalVisible: boolean;
    form: any;
    isModalOpen: boolean = false;
    EMail: string | Blob;
    checkemail: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.rolename = localStorage.getItem("clickedName");
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

        this.RegisterForm = this.formBuilder.group({
            Name: ['', Validators.required],
            Mobile: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern('[0-9]*')]],
            EMail: ['', [Validators.required, Validators.email]],
            Password: ['', [Validators.required, Validators.maxLength(30), Validators.minLength(6)]],
            //   Occupation: ['', ],
            //  Designation: ['',],
            CompanyName: ['', Validators.required],
            CompanyAddress: ['', Validators.required],
            StateName: ['',],
            DistrictName: [''],
            eligibilityCriteria: ['']
        });
    }

    ngOnInit() {
        this.getstates();

    }
    openC() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    checkFormValidity() {
        // Check if all form controls are valid
        this.form.updateValueAndValidity();
    }

    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }





    getRegistration(value: any) {
        debugger;
        this.arr = [];
        let roleID = 0;
        if (this.rolename === 'Financier') {
            roleID = 2;
        } else if (this.rolename === 'Pawn Broker') {
            roleID = 3;
        } else if (this.rolename === 'Seizer') {
            roleID = 4;
        } else if (this.rolename === 'RTA Agent') {
            roleID = 5;
        }
        var arr = [{
            Name: value.Name,
            Mobile: value.Mobile,
            EMail: value.EMail,
            Password: value.Password,
            Occupation: value.Occupation,
            Designation: value.Designation,
            CompanyName: value.CompanyName,
            CompanyAddress: value.CompanyAddress,
            StateId: this.StateId,  // Include the selected state ID
            DistrictId: this.selectedDistrictName,
            RoleID: roleID
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/FA_Registration_CRUD';
        this.generalService.PostData2(url, uploadfile).then((data: any) => {
            debugger

            if (data == 'SUCCESS') {

                alert("You have successfully signed in to the application");

                localStorage.setItem('LoginDetails', JSON.stringify(arr));

                this.router.navigate(['/1home']).then(() => {
                    window.location.reload(); // Reload the page after navigation
                });

            }
        },
            error => {
                console.error('API call failed:', error);
                alert("Failed to sign in to the application");
                // this.generalService.ShowAlert('ERROR', 'Failed to sign in to the application', 'error');
            }
        );
    }



    /*check mobile exist or not*/
    CheckMobileNo() {
        this.Mobilenum = this.RegisterForm.get('Mobile').value;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("Param2", this.Mobilenum);
        UploadFile.append("Param1", "1");
        var url = "api/FinancierLaw/Get_Check_Mobile_Email"
        this.generalService.PostData2(url, UploadFile).then(data => {
            this.mobiledata = data;
            if (data == 'Mobile Exists') {
                this.checkNo = 10;
            }
            else {
                this.checkNo = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }

    CheckEmail() {
        this.EMail = this.RegisterForm.get('EMail').value;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("Param2", this.EMail);
        UploadFile.append("Param1", "2");
        var url = "api/FinancierLaw/Get_Check_Mobile_Email"
        this.generalService.PostData2(url, UploadFile).then(data => {
            this.mobiledata = data;
            if (data == 'Email Exist') {
                this.checkemail = 12;
            }
            else {
                this.checkemail = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
    /*endregion*/



    // Method to fetch states from the server
    getstates() {
        debugger;
        var url = "api/FinancierLaw/FA_GetStates";
        this.generalService.Getdata(url).then(
            data => {
                debugger;
                this.Statedata = data;  // Store the fetched state data
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a state is selected in the dropdown
    onStateSelection(value: string) {
        debugger;
        this.StateId = value;  // Store the selected state ID
        this.Getdistricts();  // Fetch districts based on the selected state
    }

    // Method to fetch districts based on the selected state ID
    Getdistricts() {
        debugger;
        if (!this.StateId) {
            this.generalService.ShowAlert('ERROR', 'State ID is missing. Please select a state.', 'error');
            return;
        }

        var UploadFile = new FormData();
        UploadFile.append("StateId", this.StateId);  // Append the state ID to the form data

        var url = "api/FinancierLaw/FA_GetDistricts";
        this.generalService.PostData2(url, UploadFile).then(
            data => {
                debugger;
                this.DistrictName = data;  // Store the fetched district data
                if (!this.DistrictName || this.DistrictName.length === 0) {
                    this.generalService.ShowAlert('ERROR', 'No districts found for the selected state.', 'error');
                }
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a district is selected in the dropdown
    onDistrictSelection(value: string) {
        debugger;
        this.selectedDistrictName = value;  // Store the selected district name
    }


}
