<div id="services" class="services-area  ptb-100 content ">
    <div class="container ptb-70">
        <h1 class="text-center">Privacy Policy</h1>
       
        <p>This Privacy Policy explains how we collect, use, and share your information when you use our service, and your rights regarding your privacy. We use your personal data to provide and improve the service. By using our service, you agree to the collection and use of your information as described here.</p>

        <h2>Collecting and Using Your Personal Data</h2>

        <h3>Types of Data Collected</h3>

        <ul>
            <li><strong>Personal Data:</strong> We may ask for details like your email address, name, phone number, and address when you use our service.</li>
            <li><strong>Usage Data:</strong> We automatically collect data like your device's IP address, browser type, the pages you visit, and time spent on those pages.</li>
        </ul>

        <h3>How We Use Your Data</h3>

        <ul>
            <li><strong>Provide and maintain our service</strong></li>
            <li><strong>Manage your account and respond to requests</strong></li>
            <li><strong>Contact you with updates and important information</strong></li>
            <li><strong>Improve our service through analysis and trends</strong></li>
            <li><strong>Share with service providers, affiliates, or business partners when needed</strong></li>
        </ul>

        <h3>Retention of Your Data</h3>
        <p>We will keep your personal data only for as long as necessary to fulfill the purposes outlined in this policy and to comply with legal obligations.</p>

        <h3>Transferring Your Data</h3>
        <p>Your information may be processed outside your region, but we take measures to protect your data and ensure it's handled securely.</p>

        <h3>Deleting Your Data</h3>
        <p>You have the right to request the deletion of your data. You can do this by contacting us or managing your account settings.</p>

        <h3>Disclosure of Your Data</h3>
        <p>We may share your data for legal reasons, such as responding to law enforcement or protecting against liability.</p>

        <h3>Security of Your Data</h3>
        <p>We take reasonable steps to protect your personal data, but please be aware that no method of transmission over the internet is completely secure.</p>

        <h2>Children's Privacy</h2>
        <p>We do not knowingly collect data from children under 13. If you believe your child has provided personal data, please contact us to remove the information.</p>

        <h2>Links to Other Websites</h2>
        <p>Our service may contain links to external websites that are not operated by us. We are not responsible for the content or privacy practices of these sites.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We may update this policy periodically. Please check back regularly to stay informed about any changes.</p>

        <h2>Contact Us</h2>
        <ul>
            <li>Visit: <a href="https://financerlawapp.com/ContactUs" target="_blank">https://financerlawapp.com/ContactUs</a></li>
            <li>Phone: +91 9381417022</li>
        </ul>

    </div>
</div>
