import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-abscondingform',
 
  templateUrl: './abscondingform.component.html',
  styleUrl: './abscondingform.component.scss'
})
export class AbscondingformComponent {
    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    final6: any
    isUpdateMode: boolean = false;
    LegalNoticeID: number;
    cardNumber: any;
    Sname: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
     //   this.final6 = localStorage.getItem("final6");
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        // this.rolename = localStorage.getItem("clickedName");
        // this.Sname = +this.route.snapshot.paramMap.get('cardNumber');
        //   this.cardNumber = this.activeroute.snapshot.paramMap.get("Form").replace(/\_/g, " ").trim();


        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        // this.LegalNoticeID = this.cardNumber;
        this.initializeForm();
    }


    initializeForm(): void {


        this.RegisterForm = this.formBuilder.group({
            BorrowerName: ['', Validators.required],
            BorrowerFatherName: ['', Validators.required],
            BorrowerMobileNumber: ['', Validators.required],
            BorrowerAddress: ['', Validators.required],
            VehicleNumber: ['', Validators.required],
            VehicleEngineNumber: ['', Validators.required],
            VehicleChassisNumber: ['', Validators.required],
           // FinancerName: ['', Validators.required],
            PrizeMoney: ['', Validators.required],
        });

    }


    ngOnInit() {


        this.Getforms();
    }
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.final6);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
                //   const auctionedDate = this.formatDate(this.Fdata[0].datedfrom);
                this.RegisterForm.patchValue({
                    RefNo: this.Fdata[0].RefNo,

                    VehicleRegNo: this.Fdata[0].VehicleRegNo,
                    CompanyName: this.Fdata[0].CompanyName,
                    CompanyAddress: this.Fdata[0].CompanyAddress,
                    SecretaryName: this.Fdata[0].SecretaryName,
                    SecretaryAddress: this.Fdata[0].SecretaryAddress,
                    ParkedAt: this.Fdata[0].ParkedAt,
                    RTAName: this.Fdata[0].RTAName,

                });
            },
            err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }

    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }


    getRegistration(value: any) {
        debugger;

      //  if (!value.VehicleRegNo || !value.SecretaryName || !value.SecretaryAddress || !value.RTAName) {
        ////    alert("Please fill all the mandatory fields: SecretaryName, SecretaryAddress, VehicleRegNo,RTAName.");
     //       return; // Exit the function if validation fails
        //}
        this.arr = [];

        var arr = [{
            // Name: value.Name,
        
           

            BorrowerName: value.BorrowerName,
            BorrowerFatherName: value.BorrowerFatherName,
            BorrowerMobileNumber: value.BorrowerMobileNumber,
            BorrowerAddress: value.BorrowerAddress,
            VehicleNumber: value.VehicleNumber,
            VehicleEngineNumber: value.VehicleEngineNumber,
            VehicleChassisNumber: value.VehicleChassisNumber,
        FinancerName: this.loginDet[0].Name,
        PrizeMoney: value.PrizeMoney
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/AbscondedVehiclesCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form submitted successfully");
             //   localStorage.setItem('Auth', data);
                this.router.navigate(['/1home']);


            }
        },

        );
    }
    updateRegistration(value: any) {
        debugger;
        this.arr = [];

        var arr = [{

            BorrowerName: value.BorrowerName,
            BorrowerFatherName: value.BorrowerFatherName,
            BorrowerMobileNumber: value.BorrowerMobileNumber,
            BorrowerAddress: value.BorrowerAddress,
            VehicleNumber: value.VehicleNumber,
            VehicleEngineNumber: value.VehicleEngineNumber,
            VehicleChassisNumber: value.VehicleChassisNumber,
            FinancerName: this.loginDet[0].Name,
            PrizeMoney: value.PrizeMoney

        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '2');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form Updated successfully");
            //    localStorage.setItem('Auth', data);
             //   this.router.navigate(['/finalnotice6']);
             //   localStorage.removeItem('final6');



            }
        },

        );
    }



}
