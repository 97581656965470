import { Component } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { HttpClient } from '@angular/common/http';
//import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
//import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-subcription',
    templateUrl: './subcription.component.html',
    styleUrl: './subcription.component.scss'
})
export class SubcriptionComponent {
    arr: any = []; transactionID: string = '';
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    profiledata: any;
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    LoginDetails1: any; LoginDetails: any;
    selectedpast: string;
    Lastdonatedate: any;
    Status: boolean = true;
    HomeUrl: any;
    loginDet: any;

    dataResult: any;
    gridview: boolean = true;
    token: any;
    subdata: any;
    PackageName: any;
    PackageDiscount: any;
    PackagePrice: any;
    PackageDuration: any;
    PackageDescription: any;
    PaymentMode: any;
    PackageDiscounts: any;
    PackageNames: any;
    PackagePrices: any;
    PackageDurations: any;
    PackageDescriptions: any;
    PaymentModes: any;
    constructor(private genralservice: GeneralserviceService, public http: HttpClient, public router: Router,) {

        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            this.GetProduct();
        });
    }

    ngOnInit(): void {
        debugger
        this.GetProduct();
        // this.bindFormData();


    }



    GetProduct() {
        debugger;
        this.arr = [];
        this.arr.push({
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/FinancierLaw/FA_PackageCRUD1";
        this.genralservice.PostData2(url, UploadFile).then(data => {
            this.subdata = data;
            this.PackageName = data[0].PackageName;
            this.PackageDiscount = data[0].PackageDiscount;
            this.PackagePrice = data[0].PackagePrice;
            this.PackageDuration = data[0].PackageDuration;
            this.PackageDescription = data[0].PackageDescription;
            this.PaymentMode = data[0].PaymentMode;


            this.PackageNames = data[1].PackageName;
            this.PackageDiscounts = data[1].PackageDiscount;
            this.PackagePrices = data[1].PackagePrice;
            this.PackageDurations = data[1].PackageDuration;
            this.PackageDescriptions = data[1].PackageDescription;
            this.PaymentModes = data[1].PaymentMode;
            // Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }




    subscription(value: string) {
        debugger;

        // Check if value is null or an empty string
        if (!value || value.trim() === "") {
            alert("Please enter the transaction ID.");
            return;
        }

        // Check if LoginDetails is null or empty
        if (!this.LoginDetails || this.LoginDetails.length === 0) {
            console.error('LoginDetails is null or empty.');
            alert("Failed to sign in: Login details are missing.");
            return;
        }

        this.arr = [];
        const arr = [{
            RegID: this.LoginDetails[0].RegID, // Now safe to access this.LoginDetails[0].RegID
            TransactionID: value,
            Device: 'Webapp'
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/FA_subscriptionCRUD';

        console.log('Sending data to API:', uploadfile);

        this.genralservice.PostData2(url, uploadfile).then(
            (data: any) => {
                debugger;
                console.log('API response:', data);
                if (data === 'SUCCESS') {
                    alert("Thank you! Shortly you will get access to your account.");
                } else {
                    alert("Something went wrong. Please try again.");
                }
            },
          
        );
    }



}
