import { Component } from '@angular/core';

@Component({
  selector: 'app-pawanbroker-pdf', 
  
  templateUrl: './pawanbroker-pdf.component.html',
  styleUrl: './pawanbroker-pdf.component.scss'
})
export class PawanbrokerPDFComponent {

}
