<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                No Objection Letter
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> No Objection Letter</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <h5 style="color:#a52a2a"><b>No Objection Form</b></h5><br />
                <fieldset>

                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Date">Form35 Ref No</label>
                                    <input type="text" formControlName="RefNo" placeholder="Enter Your Form35 Ref No" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>-->
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Borrower Name <span style="color:red">*</span> </label>
                                    <input type="text" formControlName="BorrowerName" placeholder="Enter Your Borrower Name" id="BorrowerName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress"> Barrower Address <span style="color:red">*</span>  </label>
                                    <input type="text" formControlName="BarrowerAddress" placeholder="Enter Your Barrower Address " id="BarrowerAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile"> Barrower Mobile <span style="color:red">*</span>  </label>
                                    <input type="text" formControlName="BarrowerMobile" placeholder="Enter Your Barrower Mobile " id="BarrowerAddress" required>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="FatherName"> Father Name <span style="color:red">*</span> </label>
                                    <input type="text" formControlName="FatherName" placeholder="Enter Your Borrower Father Name" id="FatherName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleBearingNumber">Vehicle Bearing Number</label>
                                    <input type="text" formControlName="VehicleBearingNumber" placeholder="Enter Your Vehicle Bearing Number " id="VehicleBearingNumber" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleRegNo">Vehicle RegNo <span style="color:red">*</span> </label>
                                    <input type="text" formControlName="VehicleRegNo" placeholder="Enter Your Vehicle  Number " id="VehicleRegNo" required (keyup)="convertToUppercase()">
                                </div>
                            </div>





                            <div class="form-group">
                                <!-- If AgreementNo is null, undefined, or empty, show Submit button -->
                                <button class="btn" *ngIf="final4==null" (click)="getRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>

                                <!-- If AgreementNo has a value, show Update button -->
                                <button *ngIf="final4 !==null" class="btn" (click)="updateRegistration(RegisterForm.value)">
                                    <b>Update</b>
                                </button>
                            </div>

                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
    </div>
