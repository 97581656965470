import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
//import Swal from 'sweetalert2';
import * as html2pdf from 'html2pdf.js';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-finalnotice7',
 
  templateUrl: './finalnotice7.component.html',
  styleUrl: './finalnotice7.component.scss'
})
export class Finalnotice7Component {
    loginDet: any;
    Fdata: any;
    HomeUrl: any;
    todayDate: any;
    surrenderform: any; pdf: any;
    imagefile2: string | ArrayBuffer;
    TypeImage: string | ArrayBuffer;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.surrenderform = localStorage.getItem("surrenderform");
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const yyyy = today.getFullYear();

        this.todayDate = `${dd}-${mm}-${yyyy}`;



    }

    ngOnInit() {
        this.Getforms();

    }

    downloadPDF() {
        const element = document.getElementById('pdf-content');
 localStorage.removeItem("AgreementNo");

        if (!element) {
            console.error('Element not found');
            return;
        }

        // Temporarily adjust styles for PDF generation
        const originalStyle = {
            fontSize: element.style.fontSize,
            lineHeight: element.style.lineHeight,
            padding: element.style.padding
        };

        element.style.fontSize = '18px'; // Adjust as needed
        element.style.lineHeight = '1'; // Adjust as needed
        element.style.padding = '0'; // Remove padding if needed

        // Temporarily adjust paragraph spacing
        const paragraphs = element.getElementsByTagName('p');
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i].style.margin = '0'; // Remove margin
            paragraphs[i].style.padding = '0'; // Remove padding
        }

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const yyyy = today.getFullYear();
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const seconds = String(today.getSeconds()).padStart(2, '0');

        const dateTimeString = `${dd}-${mm}-${yyyy}-${hours}:${minutes}:${seconds}`;
        const filename = `SurrenderForm_${this.Fdata[0].RefNo}.pdf`;

        // Example of directory path where the file might be saved (for demonstration)
        const filePath = `C:/Users/YourUsername/Downloads/${filename}`;

        // Set PDF options
        const options = {
            margin: [0, 0.5, 0.5, 0.5], // Adjust margins if needed
            filename: filename,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };

        // Convert HTML to PDF
        html2pdf()
            .from(element)
            .set(options)
            .save()
            .then(() => {
                // Revert the styles for the front-end
                element.style.fontSize = originalStyle.fontSize;
                element.style.lineHeight = originalStyle.lineHeight;
                element.style.padding = originalStyle.padding;

                // Revert paragraph spacing
                for (let i = 0; i < paragraphs.length; i++) {
                    paragraphs[i].style.margin = ''; // Revert margin
                    paragraphs[i].style.padding = ''; // Revert padding
                }
                alert('Your pdf has been downloaded successfully to your downloads folder.');
            });

        html2pdf().from(element).set(options).toPdf().outputPdf('blob').then((pdfBlob) => {
            // Create a file-like object from the Blob
            const file = new File([pdfBlob], filename, { type: 'application/pdf' });
            this.autoUpload(file);

            // Output the file path
            console.log(`PDF saved at: ${filePath}`);
        });
    }







    autoUpload(file: File) {
        debugger
        const reader = new FileReader();
        reader.onload = () => {
            this.imagefile2 = reader.result;
            this.TypeImage = this.imagefile2;
        };
        reader.readAsDataURL(file);

        const selectedFile = file;
        const idxDot = selectedFile.name.lastIndexOf(".") + 1;
        const extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png" || extFile === "pdf" || extFile === "webp") {
            const UploadFile = new FormData();
            UploadFile.append("pdf", file);
            const url = 'api/FinancierLaw/Uploadformpdf';
            this.generalService.Postdata1(url, UploadFile).subscribe((data: any) => {
                if (data != null) {
                    debugger
                    this.pdf = data;
                    this.Insertpdfpath()
                }
            });
        } else {
            alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
        }
    }

    Insertpdfpath() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param1', this.pdf);
        uploadfile.append('Param2', this.Fdata[0].RefNo);
        var url = "api/FinancierLaw/FLA_update_Formpdfpath_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;

                if (data == 'SUCCESS') {
                    this.generalService.ShowAlert('Success', 'pdf download successful', 'success');

                }

            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
        this.router.navigate(['/1home'])
    }
    // Method to fetch states from the server
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.surrenderform);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;  // Store the fetched state data
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    navigateTOeditform(aggrementno: any) {

        this.router.navigate(['/noticeform7'], { queryParams: { AggrementNo: aggrementno } });
        localStorage.setItem('notice7', aggrementno);
    }
}
